import * as turf from '@turf/turf'
import { FeatureCollection, GeoJsonProperties, Geometry, Point } from 'geojson'
import {
  GeoJSONSource,
  LngLat,
  LngLatBounds,
  Map as MapboxMap,
} from 'mapbox-gl'
import React, {
  cloneElement,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
} from 'react'

import { BASE_URL } from '../../../../ini.json'
import { CadastreMapDebtFilterFormOutput } from '../../../common/cadastreMapDebtTypes'
import { ZpusobVyuziti } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { useMapSource } from './hooks/useMapSource'
import { useMapWatch } from './hooks/useMapWatch'

type Cache = Map<string, FeatureCollection<Geometry, GeoJsonProperties>>

export interface DrmMonitorItemDetail {
  id: string
  typ: string
  exekuce: boolean
  insolvence: boolean
  zpusobVyuziti: ZpusobVyuziti
}

type IconName =
  | 'notdef'
  | 'default-budova'
  | 'default-parcela'
  | 'default-jednotka'
  | 'garaz'
  | 'chata'
  | 'zahrada'
  | 'pole'
  | 'louka'
  | 'lesy'
  | 'rybnik'
  | 'sady'
  | ''

interface DrmMonitorData {
  id: string
  nazev: string
  pocet: number
  long: number
  lat: number
  detaily: DrmMonitorItemDetail[]
  pocetVlastniku: number
  zastavyNedobrovolne: number
  zastavySmluvni: number
  posledniVkladZapis: string
  iconName: IconName
}

const iconMapping: Record<string, Record<number | 'default', IconName>> = {
  Budova: {
    default: 'default-budova',
    18: 'garaz',
    8: 'chata',
  },
  Parcela: {
    default: 'default-parcela',
    3: 'garaz',
  },
  Jednotka: {
    default: 'default-jednotka',
    5: 'zahrada',
    2: 'pole',
    7: 'louka',
    10: 'lesy',
    11: 'rybnik',
    6: 'sady',
  },
}

function getIconName(item: DrmMonitorData): IconName {
  const detail = item.detaily
  if (!detail) return ''
  const type = detail?.[0]?.typ
  const zpusobVyuziti = detail?.[0]?.zpusobVyuziti?.kod ?? 'default'
  if (!type || !zpusobVyuziti) return 'notdef'
  return (
    iconMapping?.[type]?.[zpusobVyuziti] ||
    iconMapping?.[type]?.default ||
    'notdef'
  )
}

function convertToGeoJSON(data: DrmMonitorData[]): FeatureCollection<Point> {
  return turf.featureCollection(
    data.map(item => {
      const [detail] = item.detaily || []
      return turf.point([item.long, item.lat], {
        id: detail?.id,
        pocet: item.pocet,
        detaily: item.detaily,
        typ: detail?.typ,
        exekuce: detail?.exekuce,
        insolvence: detail?.insolvence,
        pocetVlastniku: item.pocetVlastniku,
        zastavyNedobrovolne: item.zastavyNedobrovolne,
        zastavySmluvni: item.zastavySmluvni,
        posledniVkladZapis: item.posledniVkladZapis,
        iconName: getIconName(item),
      })
    })
  )
}

function roundBounds(bounds: LngLatBounds, zoom: number): LngLatBounds {
  const precision = Math.pow(10, zoom / 5 - 1.6)

  const roundUp = (value: number) => Math.ceil(value * precision) / precision
  const roundDown = (value: number) => Math.floor(value * precision) / precision

  const [longB, latB] = bounds.getSouthWest().toArray().map(roundDown)
  const [longA, latA] = bounds.getNorthEast().toArray().map(roundUp)

  const sw = new LngLat(longB, latB)
  const ne = new LngLat(longA, latA)

  return new LngLatBounds(sw, ne)
}

function calculateDiagonal(bounds: LngLatBounds): number {
  const [longB, latB, longA, latA] = bounds.toArray().flat()
  const latDiff = latA - latB
  const longDiff = longA - longB
  const diagonala = Math.sqrt(latDiff * latDiff + longDiff * longDiff)
  return diagonala
}

function calculateMaxCount(
  originalDiagonal: number,
  roundedDiagonal: number,
  clientDimensions: { width: number; height: number },
  pixelDistance = 35
) {
  const { width, height } = clientDimensions

  const clientDiagonal = Math.hypot(width, height)
  const maxCountAlongDiagonal = clientDiagonal / pixelDistance

  const roundingAdjustmentFactor = roundedDiagonal / originalDiagonal

  return Math.ceil(maxCountAlongDiagonal * roundingAdjustmentFactor)
}

async function queryArea(
  map: MapboxMap,
  filterParams: Partial<CadastreMapDebtFilterFormOutput>,
  token: string,
  cache: Cache
) {
  const bounds = map.getBounds()
  if (!bounds) return

  const zoom = map.getZoom()
  const clientDimensions = {
    width: map.getCanvas().clientWidth,
    height: map.getCanvas().clientHeight,
  }

  const roundedBounds = roundBounds(bounds, zoom)
  const roundedDiagonal = calculateDiagonal(roundedBounds)

  const diagonal = calculateDiagonal(bounds)

  const maxPocetDiagonalne = calculateMaxCount(
    diagonal,
    roundedDiagonal,
    clientDimensions
  )

  const [longB, latB, longA, latA] = roundedBounds.toArray().flat().map(String)

  const filterFormValues = Object.fromEntries(
    Object.entries(filterParams)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, value.toString()])
  )

  const search = new URLSearchParams({
    access_token: token,
    latA,
    longA,
    latB,
    longB,
    maxPocetDiagonalne: maxPocetDiagonalne.toString(),
    ...filterFormValues,
  }).toString()
  const url = `${BASE_URL}/api/monitoringExekuci?${search}`

  const cacheKey = url

  try {
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey)
    }

    return fetch(url, {
      headers: {
        Accept: 'application/json',
        'Cache-Control': 'public, max-age=3600',
      },
    })
      .then(response => response.json())
      .then(convertToGeoJSON)
      .then(fc => {
        fc.features = fc.features.map(feature => {
          let highlight = false
          const highlightFilter = Number.parseInt(filterFormValues.highlight)
          if (!Number.isNaN(highlightFilter) && highlightFilter > 0) {
            const agoDays = feature?.properties?.posledniVkladZapis
              ? Math.floor(
                  (new Date().getTime() -
                    new Date(feature.properties.posledniVkladZapis).getTime()) /
                    (1000 * 60 * 60 * 24)
                )
              : 0
            highlight = agoDays <= highlightFilter
          }
          return {
            ...feature,
            properties: {
              ...feature.properties,
              highlight,
              insolvence:
                typeof feature?.properties?.insolvence === 'undefined'
                  ? filterFormValues.insolvence === 'true'
                  : feature.properties.insolvence,
              exekuce:
                typeof feature?.properties?.exekuce === 'undefined'
                  ? filterFormValues.exekuce === 'true'
                  : feature.properties.exekuce,
            },
          }
        })
        cache.set(cacheKey, fc)
        return fc
      })
  } catch (error) {
    console.error('Error fetching data', error)
    return turf.featureCollection([])
  }
}

export interface CadastreMapDebtSourceProps {
  id?: string
  filterParams?: CadastreMapDebtFilterFormOutput
  onData?: (data: FeatureCollection<Geometry, GeoJsonProperties>) => void
}

export const CadastreMapDebtSource: FunctionComponent<
  PropsWithChildren<CadastreMapDebtSourceProps>
> = props => {
  const token = useAppSelector(state => state.myProfile.token)
  const id = useMapSource(props.id, {
    type: 'geojson',
    data: turf.featureCollection([]),
  })
  const [map, mapUpdateTrigger] = useMapWatch(['moveend', 'zoomend'])

  const cacheRef = useRef<Cache>(new Map())

  useEffect(() => {
    updateData()
    async function updateData() {
      if (!map) return
      try {
        const data = await queryArea(
          map,
          props?.filterParams ?? {},
          token,
          cacheRef.current
        )
        if (!data) return
        map?.getSource<GeoJSONSource>(id)?.setData(data)
        props.onData?.(data)
      } catch (error) {
        console.error(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, mapUpdateTrigger, props.filterParams])

  return (
    (map && map.style && map.style._loaded && map.getSource(id) && (
      <>
        {React.Children.map(
          props.children,
          child =>
            child &&
            cloneElement(child as React.ReactElement, {
              source: id,
            })
        )}
      </>
    )) || <></>
  )
}
