import { Button, Divider, Form, Input, message } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { addHtmlContainer } from '../../../common/postServicePdf'
import {
  RecipientFragment,
  useCreatePostserviceSentLetterMutation,
  useCreatePostserviceSentRecipientMutation,
  useCreateSenderAddressMutation,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { itemRequired } from '../../utils/formHelpers'
import {
  formItemLayout,
  fullItemLayoutProps,
  validateTrigger,
} from '../../utils/layoutConst'
import { postServiceSentPath } from '../../utils/paths'
import { PostServiceLetterCreateSteps } from '../PostServiceLetterCreateSteps/PostServiceLetterCreateSteps'
import { PostServiceSenderCreateFormFragment } from '../PostServiceSenderCreateFormFragment/PostServiceSenderCreateFormFragment'

export interface PostServiceSendLetterFormValues {
  name: string
  description?: string
  city: string
  senderName: string
  street: {
    streetName: string
    houseNumber?: string
    orientationNumber?: string
  }
  zipCode: string
  saveAddress: boolean
  templateName?: string
}

export interface PostServiceSendLetterFormProps {
  content: string
  recipients: RecipientFragment[]
  templateId?: string
  listId: string
  hideModal: () => void
}

export const PostServiceSendLetterForm: FunctionComponent<PostServiceSendLetterFormProps> =
  props => {
    const [form] = Form.useForm()
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [percent, setPercent] = useState(80)
    const [, createLetter] = useCreatePostserviceSentLetterMutation()
    const [, createSentRecipient] = useCreatePostserviceSentRecipientMutation()
    const [, createSenderAddress] = useCreateSenderAddressMutation()

    const onChange = useCallback(
      (_: string, formValues: PostServiceSendLetterFormValues) => {
        if (formValues.name && formValues.description) {
          setPercent(100)
        } else if (formValues.name) {
          setPercent(95)
        } else if (formValues.description) {
          setPercent(85)
        } else {
          setPercent(80)
        }
      },
      []
    )

    const onFinish = useCallback(
      async (formValues: PostServiceSendLetterFormValues) => {
        if (props.content.length < 8) {
          // 8 is the length of the initial content
          return message.error('Nelze odeslat prázdnou korespondenci.')
        }
        setLoading(true)
        try {
          const result = await createSenderAddress({
            accountId: tenantId,
            accountUserId: userId,
            senderName: formValues.senderName,
            street: formValues.street.streetName,
            orientationNumber: formValues.street.orientationNumber,
            houseNumber: formValues.street.houseNumber,
            city: formValues.city,
            zipCode: formValues.zipCode,
            isTemplate: formValues.saveAddress,
            templateName: formValues.templateName,
          })

          const senderId =
            result.data?.createSenderAddress?.senderAddress?.id || ''

          if (!senderId) {
            setLoading(false)
            return message.error('Nepodařilo se vytvořit adresu odesílatele')
          }

          const letterResult = await createLetter({
            accountId: tenantId,
            accountUserId: userId,
            content: addHtmlContainer(props.content),
            originalTemplateId: props.templateId,
            originalListId: props.listId,
            name: formValues.name,
            description: formValues.description,
            senderAddressId: senderId,
          })

          const letterId =
            letterResult.data?.createPostserviceSentLetter
              ?.postserviceSentLetter?.id

          if (letterId) {
            await Promise.all(
              props.recipients.map(recipient =>
                createSentRecipient({
                  originalRecipientId: recipient.id,
                  accountId: tenantId,
                  accountUserId: userId,
                  sentLetterId: letterId,
                  lastname: recipient.lastname,
                  firstname: recipient.firstname,
                  gender: recipient.gender,
                  companyName: recipient.companyName,
                  callName: recipient.callName,
                  houseNumber: recipient.houseNumber,
                  orientationNumber: recipient.orientationNumber,
                  street: recipient.street,
                  zipCode: recipient.zipCode,
                  city: recipient.city,
                  country: recipient.country,
                  addressId: recipient.addressId,
                  uniquePersonKey: recipient.uniquePersonKey,
                  variable10: recipient.variable10,
                  variable9: recipient.variable9,
                  variable8: recipient.variable8,
                  variable7: recipient.variable7,
                  variable6: recipient.variable6,
                  variable5: recipient.variable5,
                  variable4: recipient.variable4,
                  variable3: recipient.variable3,
                  variable2: recipient.variable2,
                  variable1: recipient.variable1,
                  propertyId: recipient.propertyId,
                  propertyType: recipient.propertyType,
                  propertyLv: recipient.propertyLv,
                  propertyCadastralCode: recipient.propertyCadastralCode,
                  propertyCadastralName: recipient.propertyCadastralName,
                  propertyCity: recipient.propertyCity,
                  propertyAddress: recipient.propertyAddress,
                  propertyAreaNumber: recipient.propertyAreaNumber,
                  propertyName: recipient.propertyName,
                })
              )
            )
            message.success('Hromadná korespondence byla vytvořena.')
            history.push(postServiceSentPath())
          } else {
            message.error('Nepodařilo se vytvořit korespondenci.')
          }
        } catch (error) {
          message.error('Nepodařilo se vytvořit korespondenci.')
        }
        setLoading(false)
        return false
      },
      [
        createLetter,
        createSenderAddress,
        createSentRecipient,
        history,
        props.content,
        props.listId,
        props.recipients,
        props.templateId,
        tenantId,
        userId,
      ]
    )

    return (
      <Form<PostServiceSendLetterFormValues>
        {...formItemLayout}
        layout="horizontal"
        form={form}
        validateTrigger={validateTrigger}
        onFinish={onFinish}
        onValuesChange={onChange}
        className="!mt-12"
      >
        <Form.Item
          label="Název"
          name="name"
          required
          rules={itemRequired('Název korespondence je povinný')}
        >
          <Input placeholder="Název korespondence" />
        </Form.Item>

        <Form.Item label="Popis" name="description">
          <Input.TextArea
            showCount
            maxLength={1000}
            autoFocus
            placeholder="Popis korespondence"
          />
        </Form.Item>

        <Divider plain>
          <span className="text-gray-400">Údaje o odesílateli</span>
        </Divider>

        <PostServiceSenderCreateFormFragment />

        <PostServiceLetterCreateSteps
          percent={percent}
          current={2}
          status3="wait"
          status1="finish"
          className="!mt-14"
        />

        <Divider />

        <Form.Item className="!mb-0" {...fullItemLayoutProps}>
          <div className="flex justify-end">
            <div className="space-x-2">
              <Button disabled={loading} onClick={props.hideModal}>
                Zrušit
              </Button>

              <Button
                type="primary"
                loading={loading}
                disabled={percent < 95}
                onClick={form.submit}
              >
                Uložit korespondenci
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }
