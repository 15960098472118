import { DoubleLeftOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { CollectionType } from '../../../common/documentCollectionTypes'
import { CuzkEpoDownloadButton } from '../CuzkEpoDownloadButton/CuzkEpoDownloadButton'
import { CuzkRegenerateFilesButton } from '../CuzkRegenerateFilesButton/CuzkRegenerateFilesButton'

export interface CuzkEpoDetailTabBarExtraContentProps {
  id: string
  isHugeSubject: boolean
}

export const CuzkEpoDetailTabBarExtraContent: FunctionComponent<CuzkEpoDetailTabBarExtraContentProps> =
  props => {
    const [isOpen, setIsOpen] = useState(false)
    const handleOpen = useCallback(() => {
      setIsOpen(open => !open)
    }, [])

    const openButtonProps = useMemo<ButtonProps>(
      () => ({
        ghost: true,
        type: 'primary',
        shape: 'circle',
        size: 'small',
        title: isOpen ? 'Skrýt možnosti' : 'Zobrazit více možností',
        style: {
          borderStyle: 'dashed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all',
          transitionDuration: '0.7s',
          transitionTimingFunction: 'ease-in-out',
          rotate: isOpen ? '180deg' : '0deg',
        },
        onClick: handleOpen,
        icon: (
          <DoubleLeftOutlined
            style={{
              fontSize: '10px',
            }}
          />
        ),
      }),
      [handleOpen, isOpen]
    )
    return (
      <div className="flex space-x-2 items-center">
        <Button {...openButtonProps} />
        <div
          className={
            'overflow-hidden transition-all duration-500 flex space-x-2 ease-in-out ' +
            (isOpen ? 'max-w-lg' : 'max-w-0')
          }
        >
          <CuzkRegenerateFilesButton
            id={props.id}
            collectionType={CollectionType.EPO}
          />
          <CuzkEpoDownloadButton type="json" id={props.id} />
          <CuzkEpoDownloadButton
            type="html"
            id={props.id}
            isHugeSubject={props.isHugeSubject}
          />
        </div>
        <CuzkEpoDownloadButton type="xml" id={props.id} />
        <CuzkEpoDownloadButton
          type="pdf"
          id={props.id}
          isHugeSubject={props.isHugeSubject}
        />
      </div>
    )
  }
