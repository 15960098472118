import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, message, Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { BASE_URL } from '../../../../ini.json'
import { useAppSelector } from '../../../redux/hooks'
import { validateTrigger } from '../../utils/layoutConst'
import { pricelist } from '../../utils/pricelist'
import { useAssignLabels } from '../../utils/useAssignLabels'
import { CuzkEpoFormOfoFragment } from '../CuzkEpoFormOfoFragment/CuzkEpoFormOfoFragment'
import { CuzkEpoFormOpoFragment } from '../CuzkEpoFormOpoFragment/CuzkEpoFormOpoFragment'
import { CuzkEpoFormPersonTypeFragment } from '../CuzkEpoFormPersonTypeFragment/CuzkEpoFormPersonTypeFragment'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'
import { PricePopconfirm } from '../PricePopconfirm/PricePopconfirm'

export const CUZK_PERSON_TYPES = {
  OFO: 'OFO',
  OPO: 'OPO',
  SJM: 'SJM',
} as const

export interface CuzkEpoModalFormValues {
  birthDate?: string
  firstname?: string
  lastname?: string
  ico?: string
  name?: string
  type: typeof CUZK_PERSON_TYPES[keyof typeof CUZK_PERSON_TYPES]
}
export interface CuzkEpoModalButtonProps {
  initialValues?: CuzkEpoModalFormValues
  refetch?: (value: boolean) => void
  buttonProps?: ButtonProps
  buttonText?: string
  className?: string
}

const initialValues = {
  type: CUZK_PERSON_TYPES.OFO,
}

export const CuzkEpoModalButton: FunctionComponent<CuzkEpoModalButtonProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)
    const token = useAppSelector(state => state.myProfile.token)
    const { assignLabels } = useAssignLabels()
    const [form] = Form.useForm()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [personType, setPersonType] = useState<
      CuzkEpoModalFormValues['type']
    >(props.initialValues?.type || CUZK_PERSON_TYPES.OFO)
    const [showHelp, setShowHelp] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [labelsId, setLabelsId] = useState<string[] | []>([])

    const toggleModalVisibility = useCallback(() => {
      setIsModalVisible(prev => !prev)
    }, [])

    const handleSwitch = useCallback((value: string | number) => {
      setPersonType(value as CuzkEpoModalFormValues['type'])
    }, [])

    const handleOnClick = useCallback(async () => {
      const validated = await form.validateFields().catch(() => false)
      setDisabled(!validated)
    }, [form])

    const handleOnConfirm = useCallback(async () => {
      const formValues = form.getFieldsValue()
      setLoading(true)
      try {
        const response = await fetch(`${BASE_URL}/api/cuzk/epo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            tenantId,
            userId,
            typ: formValues.type,
            nazev:
              formValues.type === CUZK_PERSON_TYPES.OPO
                ? formValues.name || ''
                : (formValues.lastname || '') +
                  ' ' +
                  (formValues.firstname || ''),
            rcIco:
              formValues.type === CUZK_PERSON_TYPES.OPO
                ? formValues.ico
                : formValues.birthDate,
          }),
        })

        if (response.status !== 200)
          return message.error('Při vyhledávání došlo k chybě.')

        await response.json().then(async data => {
          await assignLabels({
            cuzkEpoId: data,
            labelsId,
          })
        })
      } catch (error) {
        message.error('Při vyhledávání došlo k chybě.')
      }
      setLoading(false)
      toggleModalVisibility()
      return props.refetch && props.refetch(true)
    }, [
      assignLabels,
      form,
      labelsId,
      props,
      tenantId,
      toggleModalVisibility,
      token,
      userId,
    ])

    const popconfrimProps = useMemo(() => {
      if (disabled)
        return {
          okButtonProps: {
            disabled: true,
          },
          title: (
            <span className="text-red-500">
              Prosím zadejte všechny povinné údaje.
            </span>
          ),
        }
      if (loading)
        return {
          okButtonProps: {
            loading: true,
          },
        }
      return
    }, [disabled, loading])

    const footer = useMemo(
      () => (
        <div className="flex justify-between">
          <Button
            icon={<InfoCircleOutlined />}
            type="link"
            onClick={() => setShowHelp(!showHelp)}
          >
            {showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
          </Button>
          <div>
            <Button onClick={toggleModalVisibility}>Zrušit</Button>
            <PricePopconfirm
              popconfrimProps={popconfrimProps}
              onConfirm={handleOnConfirm}
              itemPrice={pricelist.EPO}
              message={`Vyhledání subjektu v evidenci práv ČÚZK je zpoplatněno ${pricelist.EPO} Kr.`}
            >
              <Button loading={loading} type="primary" onClick={handleOnClick}>
                Vyhledat
              </Button>
            </PricePopconfirm>
          </div>
        </div>
      ),
      [
        handleOnClick,
        handleOnConfirm,
        loading,
        popconfrimProps,
        showHelp,
        toggleModalVisibility,
      ]
    )

    return (
      <div className={props.className}>
        <Button
          type="primary"
          onClick={toggleModalVisibility}
          {...props.buttonProps}
        >
          {props.buttonText || 'Nové vyhledávání'}
        </Button>
        <Modal
          title="Vyhledat evidenci práv v ČÚZK"
          open={isModalVisible}
          onCancel={toggleModalVisibility}
          footer={footer}
        >
          <Form<CuzkEpoModalFormValues>
            layout="vertical"
            className="!mt-8"
            initialValues={props.initialValues || initialValues}
            form={form}
            validateTrigger={validateTrigger}
          >
            <CuzkEpoFormPersonTypeFragment
              personType={personType}
              handleSwitch={handleSwitch}
            />
            {personType === CUZK_PERSON_TYPES.OFO ? (
              <CuzkEpoFormOfoFragment showHelp={showHelp} />
            ) : (
              <CuzkEpoFormOpoFragment showHelp={showHelp} />
            )}
          </Form>
          <div className="mb-2 mt-6">
            Štítek <span className="text-gray-400">(nepovinné)</span>
          </div>
          <div className="mb-10">
            <LabelSelectInput disabled={loading} onChange={setLabelsId} />
          </div>
        </Modal>
      </div>
    )
  }
