import { Tag, Typography } from 'antd'
import React, { FunctionComponent, memo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ForclosureMonitoringRecord } from '../../../common/foreclosureMonitoringTypes'
import { TransformedPropertyLabelDataItem } from '../../../common/propertyDetailTypes'
import { UserActivityLog } from '../../../common/userActivityLogTypes'
import { PropertyTableNoteItem } from '../../utils/propertyDetailNoteHelpers'
import { Day } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PropertyMonitoringTilesItemFooter } from '../PropertyMonitoringTilesItemFooter/PropertyMonitoringTilesItemFooter'
import { PropertyMonitoringTilesItemImage } from '../PropertyMonitoringTilesItemImage/PropertyMonitoringTilesItemImage'
import { PropertyMonitoringTilesItemRibbon } from '../PropertyMonitoringTilesItemRibbon/PropertyMonitoringTilesItemRibbon'
import { PropertyMonitoringTilesItemRow } from '../PropertyMonitoringTilesItemRow/PropertyMonitoringTilesItemRow'
import { PropertyNoteModalButton } from '../PropertyNoteModalButton/PropertyNoteModalButton'

export interface ForeclosureMonitoringTilesItemProps
  extends ForclosureMonitoringRecord {
  propertyType: PROPERTY_TYPES
  userActivityLog?: UserActivityLog | null
  propertyNote?: PropertyTableNoteItem | null
  propertyLabels?: TransformedPropertyLabelDataItem
}

export const ForeclosureMonitoringTilesItem: FunctionComponent<ForeclosureMonitoringTilesItemProps> =
  memo(props => {
    return (
      <PropertyMonitoringTilesItemRibbon
        alertDateTo={props.posledniVkladZapis}
        userActivityLog={props.userActivityLog}
        propertyLabels={props.propertyLabels}
      >
        <div
          className={`flex flex-col justify-between group rounded-lg border overflow-hidden shadow-sm hover:shadow-lg h-full ${
            props.userActivityLog
              ? 'opacity-70 hover:opacity-100 transition-all'
              : ''
          }`}
        >
          <div className="relative w-full pb-full">
            <PropertyMonitoringTilesItemImage
              className="h-80 lg:h-96 2xl:h-80 4xl:h-96"
              propertyId={props.id}
              coordinates={props.souradnice}
              propertyType={props.propertyType}
            />
          </div>
          <div className="flex flex-col justify-between grow">
            <div className="p-5 pb-0 transition-all text-gray-400">
              <Typography.Title
                level={4}
                className="!mb-4 text-gray-800 first-letter:uppercase"
              >
                {props.zpusobVyuziti?.nazev || props.druhPozemku?.nazev}
              </Typography.Title>

              <PropertyMonitoringTilesItemRow
                label="Výměra pozemku"
                value={
                  <Money decimalNumbers={0} symbol="m²" amount={props.vymera} />
                }
                condition={!!props.vymera}
              />
              <PropertyMonitoringTilesItemRow
                label="Druh pozemku"
                value={props.druhPozemku?.nazev}
                condition={
                  !!props.zpusobVyuziti?.nazev && !!props.druhPozemku?.nazev
                }
              />
              <PropertyMonitoringTilesItemRow
                label="Kat. pracoviště"
                value={props.pracoviste.nazev}
              />
              <PropertyMonitoringTilesItemRow
                label="Počet vlastníků"
                value={props.pocetVlastniku}
              />
              <PropertyMonitoringTilesItemRow
                label="Poslední změna"
                value={<Day>{props.posledniVkladZapis}</Day>}
              />
              <PropertyMonitoringTilesItemRow
                label="Poznámka"
                condition={!!props.propertyNote}
                value={
                  <PropertyNoteModalButton
                    className="truncate max-w-[220px] text-gray-800"
                    note={props.propertyNote}
                  />
                }
              />

              <div className="pt-2 mb-2 flex gap-1">
                {props.exekuce && (
                  <Tag color="error" title="Exekuce" className="!mr-0 truncate">
                    Exekuce
                  </Tag>
                )}
                {props.insolvence && (
                  <Tag
                    color="error"
                    title="Insolvence"
                    className="!mr-0 truncate"
                  >
                    Insolvence
                  </Tag>
                )}
                {props.zastavyNedobrovolne && (
                  <Tag
                    color="error"
                    title="Zástavní právo nedobrovolné"
                    className="!mr-0 truncate"
                  >
                    Nedobrovolná zástava
                  </Tag>
                )}
                {props.zastavySmluvni && (
                  <Tag
                    color="orange"
                    title="Zástavní právo smluvní"
                    className="!mr-0 truncate"
                  >
                    Smluvní zástava
                  </Tag>
                )}
              </div>
            </div>
          </div>
          <PropertyMonitoringTilesItemFooter
            id={props.id}
            propertyType={props.propertyType}
          />
        </div>
      </PropertyMonitoringTilesItemRibbon>
    )
  })
