import { EyeOutlined, MessageOutlined, TagOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { memo, useMemo } from 'react'

import { useDeclension } from '../../utils/useDeclension'

export interface InsolvencyMonitoringCompactTableInfoIconsProps {
  noteCount: number
  labelCount: number
  activityLogCount: number
  totalItems: number
}

export const InsolvencyMonitoringCompactTableInfoIcons = memo(
  ({
    noteCount,
    labelCount,
    activityLogCount,
    totalItems,
  }: InsolvencyMonitoringCompactTableInfoIconsProps) => {
    const decline = useDeclension()

    const icons = useMemo(
      () => [
        {
          visible: labelCount > 0,
          column: 1,
          Icon: TagOutlined,
          isHighlighted: labelCount === totalItems,
          title:
            labelCount === totalItems
              ? `Všechny nemovitosti mají přiřazený štítek`
              : `Obsahuje ${decline(
                  labelCount,
                  'property'
                )} s přiřazeným štítkem`,
        },
        {
          visible: noteCount > 0,
          column: 2,
          Icon: MessageOutlined,
          isHighlighted: noteCount === totalItems,
          title:
            noteCount === totalItems
              ? `Všechny nemovitosti obsahují poznámku`
              : `Obsahuje ${decline(noteCount, 'property', true)} s poznámkou`,
        },
        {
          visible: activityLogCount > 0,
          column: 3,
          Icon: EyeOutlined,
          isHighlighted: activityLogCount === totalItems,
          title:
            activityLogCount === totalItems
              ? `Všechny nemovitosti jste si zobrazili`
              : `${decline(
                  activityLogCount,
                  'property',
                  true
                )} jste si již zobrazili`,
        },
      ],
      [activityLogCount, decline, labelCount, noteCount, totalItems]
    )

    return (
      <div className="grid grid-cols-3 gap-2 w-16">
        {icons.map(
          ({ visible, column, Icon, isHighlighted, title }) =>
            visible && (
              <Tooltip
                key={column}
                className={`col-start-${column}`}
                title={title}
              >
                <Icon className={isHighlighted ? '' : 'text-gray-400'} />
              </Tooltip>
            )
        )}
      </div>
    )
  }
)
