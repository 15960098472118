export const pricelist = {
  CEE: 20,
  CUZK: 1,
  CUZK_LISTINA: 4,
  MONIT: 40,
  LV: 12.5,
  EPO: 12.5,
  EPO_RESTRICTION_CHECK: 500,
  EPO_LV_CHECK: 6,
  marketplaceContact: 300,
  postService: {
    ORDINARY_LETTER: 32.5,
    REGISTERED: 79,
    ORDINARY_LETTER_INTERNATIONAL: 55,
    REGISTERED_INTERNATIONAL: 132,
  },
  person: {
    BIRTH_DATE: 1,
    DATABOX: 1,
  },
  WATCHDOG: 1,
}
