import axios from 'axios'
import React, { FunctionComponent, useCallback, useState } from 'react'

import {
  PostServiceLinkedProperty,
  PostServiceRecipient,
  SubjectsInPostService,
} from '../../../common/postServiceTypes'
import { useAppSelector } from '../../../redux/hooks'
import { fetchAddressById } from '../../utils/fetchAresAddress'
import { requestSubjectPath } from '../../utils/paths'
import { AddRecipientModalButton } from '../AddRecipientModalButton/AddRecipientModalButton'
import { AddRecipientModalButtonDataAlert } from '../AddRecipientModalButtonDataAlert/AddRecipientModalButtonDataAlert'
import { SubjectTableCellRecipientLetter } from '../SubjectTableCellRecipientLetter/SubjectTableCellRecipientLetter'
import { SubjectTableCellRecipientList } from '../SubjectTableCellRecipientList/SubjectTableCellRecipientList'

export interface SubjectTableCellPostServiceProps {
  addressId?: number
  fullAddress?: string
  fullName?: string
  companyName?: string
  subjectId?: string
  property?: Partial<PostServiceLinkedProperty>
  postServiceData?: SubjectsInPostService
  uniquePersonKey?: string
  refetch?: () => void
}

export const SubjectTableCellPostService: FunctionComponent<SubjectTableCellPostServiceProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const [recipientData, setRecipientData] =
      useState<Partial<PostServiceRecipient>>()

    const fetchAddress = useCallback(async () => {
      if (recipientData || !props.addressId) return
      const addressData = await fetchAddressById(props.addressId)
      const subject = props.subjectId
        ? await axios.get(requestSubjectPath() + token + '/' + props.subjectId)
        : { data: null }

      setRecipientData({
        uniquePersonKey: props.uniquePersonKey,
        companyName: props.companyName || '',
        firstname: subject.data?.fyzickaOsoba?.krestniJmeno || '',
        lastname: subject.data?.fyzickaOsoba?.prijmeni || '',
        street: addressData?.nazevUlice || addressData?.nazevObce || '',
        houseNumber: addressData?.cisloDomovni?.toString() || '',
        orientationNumber: addressData?.cisloOrientacni?.toString() || '',
        city: addressData?.nazevObce || '',
        country: 'CZ',
        zipCode: addressData?.psc?.toString() || '',
        addressId: props.addressId?.toString() || '',
        ...props.property,
      })
    }, [
      recipientData,
      props.addressId,
      props.companyName,
      props.property,
      props.subjectId,
      props.uniquePersonKey,
      token,
    ])

    return (
      <div className="flex space-x-2 items-center">
        <div title="Přidat subjekt do hromadné pošty" onClick={fetchAddress}>
          <AddRecipientModalButton
            record={recipientData}
            refetch={props.refetch}
            message={
              <AddRecipientModalButtonDataAlert
                addressId={props.addressId}
                fullAddress={props.fullAddress}
                fullName={props.fullName}
                subjectName={
                  recipientData?.firstname && recipientData?.lastname
                }
              />
            }
          />
        </div>
        <div className="w-5">
          <SubjectTableCellRecipientList
            postServiceList={props.postServiceData?.list}
          />
        </div>
        <SubjectTableCellRecipientLetter
          postServiceLetter={props.postServiceData?.letter}
        />
      </div>
    )
  }
