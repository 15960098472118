import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, message, Modal } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import Tooltip from 'antd/lib/tooltip'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import {
  PropertyLabelConnection,
  PropertyLabelData,
} from '../../../common/propertyDetailTypes'
import {
  useCreateLabelConnectionMutation,
  useCreatePropertyDetailLabelMutation,
  useDeleteLabelConnectionMutation,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { usePropertyDataForNewLabel } from '../../utils/usePropertyDataForNewLabel'
import { useToggleState } from '../../utils/useToggleState'
import { LabelSelectInput } from '../LabelSelectInput/LabelSelectInput'

interface PropertyDetailEditLabelModalProps {
  labelConnections: PropertyLabelConnection[]
  refetch?: () => void
  buttonText?: React.ReactNode
  buttonProps?: BaseButtonProps
  propertyData?: PropertyLabelData
  propertyType: PROPERTY_TYPES
  propertyId: string
}

export const PropertyDetailEditLabelModal: FunctionComponent<PropertyDetailEditLabelModalProps> =
  props => {
    const lv = useAppSelector(state => state.property.drm?.data?.lv)
    const [open, toggleOpen] = useToggleState()
    const [loading, toggleLoading] = useToggleState(false)
    const [currentLabels, setCurrentLabels] = useState<string[]>()

    const [, createPropertyLabel] = useCreatePropertyDetailLabelMutation()
    const [, addLabelConnection] = useCreateLabelConnectionMutation()
    const [, deleteLabelConnection] = useDeleteLabelConnectionMutation()

    const propertyDataForNewLabel = usePropertyDataForNewLabel(
      props.propertyId,
      props.propertyType,
      !!props.propertyData?.propertyLabelId || !open
    )

    const modalTitle = useMemo(() => {
      const title = `Editace štítků pro ${
        props.propertyData?.propertyName ||
        propertyDataForNewLabel?.propertyName ||
        'detail nemovitosti'
      }`
      return title.length > 65 ? `${title.slice(0, 65)}...` : title
    }, [
      propertyDataForNewLabel?.propertyName,
      props.propertyData?.propertyName,
    ])

    const initialLabels = useMemo(
      () =>
        props.labelConnections?.map(label => label?.labelByLabelId?.id || '') ||
        [],
      [props.labelConnections]
    )

    const addedLabels = useMemo(
      () =>
        currentLabels?.filter(label => !initialLabels.includes(label)) || [],
      [currentLabels, initialLabels]
    )

    const labelsToDelete = useMemo(
      () =>
        props.labelConnections?.reduce<string[]>((acc, label) => {
          if (
            label?.labelByLabelId?.id &&
            !currentLabels?.includes(label.labelByLabelId.id) &&
            label.id
          ) {
            acc.push(label.id)
          }
          return acc
        }, []) || [],
      [currentLabels, props.labelConnections]
    )

    const onSave = useCallback(async () => {
      toggleLoading()
      try {
        let entityId = props.propertyData?.propertyLabelId

        if (!entityId) {
          const result =
            propertyDataForNewLabel &&
            (await createPropertyLabel(propertyDataForNewLabel))

          entityId = result?.data?.createPropertyLabel?.propertyLabel?.id
          if (!entityId) throw new Error('Failed to create property label')
        }

        await Promise.all([
          ...addedLabels.map(label =>
            addLabelConnection({ propertyLabelId: entityId, labelId: label })
          ),
          ...labelsToDelete.map(id => deleteLabelConnection({ id })),
        ])

        message.success('Štítky byly upraveny.')
        props.refetch?.()
        toggleOpen()
      } catch (error) {
        message.error('Nepodařilo se uložit štítky. Zkuste to prosím znovu.')
      } finally {
        toggleLoading()
      }
    }, [
      addLabelConnection,
      addedLabels,
      createPropertyLabel,
      deleteLabelConnection,
      labelsToDelete,
      propertyDataForNewLabel,
      props,
      toggleLoading,
      toggleOpen,
    ])

    const icon = useMemo(
      () =>
        props.labelConnections?.length ? (
          <EditOutlined />
        ) : (
          <PlusOutlined className="!text-xs" />
        ),
      [props.labelConnections]
    )

    if (!lv && !props.labelConnections?.length)
      return (
        <Tooltip title="Pro editaci štítků je potřeba nejdříve importovat údaje o nemovitosti.">
          <Button
            type="link"
            size="small"
            className="opacity-80 hover:opacity-100"
            icon={icon}
            title="Editovat štítky"
            disabled
          >
            {props.buttonText}
          </Button>
        </Tooltip>
      )

    return (
      <>
        <Button
          onClick={toggleOpen}
          type="link"
          size="small"
          className="opacity-40 hover:opacity-100"
          title="Editovat štítky"
          icon={icon}
          {...props.buttonProps}
        >
          {props.buttonText}
        </Button>
        {open && (
          <Modal
            title={modalTitle}
            open={open}
            onCancel={toggleOpen}
            onOk={onSave}
            okText="Uložit"
            cancelText="Zrušit"
            confirmLoading={loading}
            width={600}
          >
            <div className="mt-4">
              <LabelSelectInput
                initialValue={initialLabels}
                onChange={setCurrentLabels}
              />
            </div>
          </Modal>
        )}
      </>
    )
  }
