import React, { FunctionComponent } from 'react'
import { Layer } from 'react-map-gl'

export interface CadastreMapInsolvencyLayersProps {
  source: string
  enabled: boolean
}

export const CadastreMapInsolvencyLayers: FunctionComponent<CadastreMapInsolvencyLayersProps> =
  ({ source, enabled }) => {
    return (
      <>
        <Layer
          id="insolvency-highlight"
          source={source}
          type="symbol"
          filter={['==', 'highlight', true]}
          layout={{
            visibility: enabled ? 'visible' : 'none',
            'icon-image': 'pulsing-dot',
            'icon-size': 2,
          }}
        />
        <Layer
          id="insolvency-circle"
          source={source}
          type="circle"
          layout={{ visibility: enabled ? 'visible' : 'none' }}
          paint={{
            'circle-color': [
              'case',
              ['boolean', ['get', 'exekuce'], false],
              '#f87171',
              ['boolean', ['get', 'insolvence'], false],
              '#f59e0b',
              '#eff6ff',
            ],
            'circle-radius': [
              'case',
              ['>', ['to-number', ['get', 'pocet']], 1],
              20,
              6,
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': '#1c1917',
          }}
        />
        <Layer
          id="insolvency-icon-or-counttext"
          source={source}
          type="symbol"
          filter={['>', ['to-number', ['get', 'pocet']], ['to-number', '1']]}
          layout={{
            visibility: enabled ? 'visible' : 'none',
            'text-field': ['get', 'pocet'],
            'text-font': ['Open Sans Bold'],
            'text-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0,
              1,
              10,
              10,
              16,
              22,
              24,
            ],
          }}
          paint={{
            'text-halo-color': '#fff',
            'text-halo-width': 1,
            'text-color': '#000',
          }}
        />
      </>
    )
  }
