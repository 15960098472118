import React, { FunctionComponent } from 'react'

import { CollectionType } from '../../../common/documentCollectionTypes'
import { CuzkEpoDownloadButton } from '../CuzkEpoDownloadButton/CuzkEpoDownloadButton'
import { CuzkRegenerateFilesButton } from '../CuzkRegenerateFilesButton/CuzkRegenerateFilesButton'

export interface CuzkLvDetailTabBarExtraContentProps {
  id: string
}

export const CuzkLvDetailTabBarExtraContent: FunctionComponent<CuzkLvDetailTabBarExtraContentProps> =
  props => {
    return (
      <div className="flex space-x-2 items-center">
        <CuzkRegenerateFilesButton
          id={props.id}
          collectionType={CollectionType.LV}
        />
        <CuzkEpoDownloadButton type="xml" id={props.id} />
        <CuzkEpoDownloadButton type="json" id={props.id} />
        <CuzkEpoDownloadButton type="html" id={props.id} />
        <CuzkEpoDownloadButton type="pdf" id={props.id} />
      </div>
    )
  }
