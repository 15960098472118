import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { Breakpoint, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { FunctionComponent, useMemo } from 'react'

import { PostServiceRecipient } from '../../../common/postServiceTypes'
import { stringSorter } from '../../utils/generalTableHelpers'
import {
  postServicePropertyRenderer,
  showVariableColumns,
} from '../../utils/postServiceHelpers'

export interface PostServiceImportRecipientPreviewTableProps {
  data?: PostServiceRecipient[]
}

export const PostServiceImportRecipientPreviewTable: FunctionComponent<PostServiceImportRecipientPreviewTableProps> =
  props => {
    const showVariable = useMemo(() => {
      const variables: { [key: string]: Breakpoint[] | undefined } = {}
      for (let i = 2; i <= 10; i++) {
        variables[`variable${i}`] = props.data?.some(
          item => item?.[`variable${i}` as keyof Maybe<PostServiceRecipient>]
        )
          ? undefined
          : ['' as Breakpoint]
      }

      return variables
    }, [props.data])

    const scroll = useMemo(() => {
      const countVariables = Object.values(showVariable).filter(
        item => item === undefined
      ).length
      return { x: 1000 + countVariables * 200 }
    }, [showVariable])

    const columns = useMemo<ColumnsType<PostServiceRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Data',
          dataIndex: 'error',
          className: 'w-10 text-center',
          render: error =>
            error?.length > 0 ? (
              <Tooltip title={'Chybná data: ' + error?.join(', ')}>
                <WarningOutlined className="text-red-500" />
              </Tooltip>
            ) : (
              <CheckOutlined className="text-green-500" />
            ),
        },

        {
          title: 'Společnost',
          dataIndex: 'companyName',
          sorter: stringSorter('companyName'),
        },
        {
          title: 'Jméno',
          dataIndex: 'recipientName',
          sorter: stringSorter('lastname'),
          render: (_, record) => (
            <span>
              <span className={record?.firstname ? '' : 'text-red-500'}>
                {record?.firstname || '<křestní jm.>'}
              </span>
              <span className={record?.lastname ? 'pl-1' : 'text-red-500'}>
                {record?.lastname || '<příjmení>'}
              </span>
            </span>
          ),
        },
        {
          title: 'Pohlaví',
          dataIndex: 'gender',
          sorter: stringSorter('lastname'),
          render: (_, record) =>
            record?.gender === 'm'
              ? 'Muž'
              : record?.gender === 'f'
              ? 'Žena'
              : '',
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          key: 'address',
          sorter: stringSorter('street'),
          render: (_, record) => {
            return (
              <span>
                <span className={record?.street ? '' : 'text-red-500'}>
                  {record?.street || '<název ulice>'}
                </span>
                <span className={record?.houseNumber ? 'pl-1' : 'text-red-500'}>
                  {record?.houseNumber || '<číslo domu>'}
                </span>
                <span>
                  {record?.orientationNumber
                    ? '/' + record?.orientationNumber
                    : ''}
                </span>
                <span className={record?.city ? 'pl-1' : 'text-red-500'}>
                  {record?.city || '<obec>'}
                </span>
                <span className={record?.zipCode ? 'pl-1' : 'text-red-500'}>
                  {record?.zipCode || '<PSČ>'}
                </span>
                <span className={record?.country ? 'pl-1' : 'text-red-500'}>
                  {record?.country || '<kód země>'}
                </span>
              </span>
            )
          },
        },
        ...showVariableColumns(props.data),
        {
          title: 'Nemovitost',
          dataIndex: 'property',
          sorter: stringSorter('propertyName'),
          render: postServicePropertyRenderer,
        },
      ],
      [props.data]
    )

    return (
      <Table
        dataSource={props.data}
        columns={columns}
        rowKey="id"
        size="small"
        rowClassName="bg-[#fafafa]"
        className="mb-6"
        scroll={scroll}
      />
    )
  }
