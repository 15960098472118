import React from 'react'
import { Layer, Source } from 'react-map-gl'

const tiles = [
  'https://mapy.geology.cz/arcgis/rest/services/Geohazardy/radon50/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=3857&layers=&layerDefs=&size=256%2C256&imageSR=3857&format=png&transparent=true&dpi=&time=&timeRelation=esriTimeRelationOverlaps&layerTimeOptions=&dynamicLayers=&gdbVersion=&mapScale=&rotation=&datumTransformations=&layerParameterValues=&mapRangeValues=&layerRangeValues=&clipping=&spatialFilter=&f=image',
]

export const CadastreMapRadonDisplay = () => {
  return (
    <Source id="radon" type="raster" tiles={tiles} tileSize={256}>
      <Layer
        id="radon"
        type="raster"
        source="radon"
        minzoom={0}
        maxzoom={19}
        paint={{ 'raster-opacity': 0.7 }}
      />
    </Source>
  )
}
