import { Maybe } from 'graphql/jsutils/Maybe'

import { PROPERTY_TYPES } from './drmTypes'
import { NalezenaAdresa, RzpSubjectTypeKey } from './rzpTypes'

export enum PROPERTY_TAB_KEYS {
  NOTE = 'poznamky',
  OWNERSHIP = 'vlastnictvi',
  TIMELINE = 'casova-osa',
  SUBJECT = 'subjekty-na-adrese',
  PROCEEDING = 'rizeni',
  PRICE_ESTIMATE = 'cenovy-odhad',
  RISY = 'detail-obce',
  WATCHDOG = 'hlidac',
}

export interface PropertyDetailSubjectsTableItem {
  id?: string
  key: string
  typ: RzpSubjectTypeKey
  nazev: string
  vzdalenost: number | null
  adresa?: string | null
  sidlo?: string | null
  nalezenaAdresa?: NalezenaAdresa | null
  kodAdresnihoMista?: number | null
  uniquePersonKey: string
  ico?: string
  aktualni: boolean
  angazovaneOsoby?: PropertyDetailSubjectsTableItem[]
  datumOd?: string
  datumDo?: string
  angazma?: {
    datumDo?: string | null
    datumOd?: string | null
    nazevAngazma?: string | null
    predmetPodnikani?: string | null
  }[]
  match?: string
  datumNarozeni?: string
  predmetPodnikani?: string
  velikostPodilu?: number
}

export interface PropertyDetailLabelData {
  id?: string
  propertyId: string
  propertyLabelId?: string
  type: PROPERTY_TYPES
  name: string
  cadastralCode: string
  cadastralName: string
  lv: string
}

export interface PropertyLabel {
  id: string
  propertyId: string
  propertyLv: string
  propertyName: string
  propertyType: PROPERTY_TYPES
  propertyCadastralName: string
  propertyCadastralCode: string
  createdAt: string
  labelConnectionsByPropertyLabelId: {
    nodes: LabelConnection[] | null | undefined
    totalCount: number
  }
}

export interface LabelConnection {
  id?: string | null
  labelByLabelId?: Label | null
}

export type PropertyLabelConnection = Maybe<{
  id?: string | null
  labelByLabelId?: Maybe<{
    id: string
  }>
}>

export interface Label {
  description?: string
  iconKey: string
  colorKey: string
  id: string
  name: string
}

export interface PropertyLabelData {
  propertyId: string
  propertyName: string
  propertyType: PROPERTY_TYPES
  propertyCadastralName: string
  propertyCadastralCode: string
  propertyLv: string
  propertyLabelId?: string
  createdAt?: Date
}

export interface PropertyLabelInputData extends PropertyLabelData {
  accountId: string
  accountUserId: string
}

export type TransformedPropertyLabelDataItem = {
  labelConnectionsByPropertyLabelId: {
    nodes: LabelConnection[]
    totalCount: number
  }
  propertyData: PropertyLabelData
}

export interface TransformedPropertyLabelData {
  [propertyId: string]: TransformedPropertyLabelDataItem
}
