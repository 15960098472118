import {
  LabelConnectionFragmentFragment,
  Maybe,
  PostserviceLetterType,
} from '../graphql/generated'
import { PROPERTY_TYPES } from './drmTypes'

export const postServiceTestRecipient = {
  companyName: 'Název společnosti s.r.o.',
  firstname: 'Jan',
  lastname: 'Novák',
  callName: 'Nováku',
  gender: 'm',
  houseNumber: '21',
  orientationNumber: '1009',
  id: '00000000-0000-0000-0000-000000000000',
  street: 'U Líce',
  city: 'Praha',
  zipCode: '11000',
  country: 'Czech Republic',

  variable1: 'cvičná proměnná 1',
  variable2: 'cvičná proměnná 2',
  variable3: 'cvičná proměnná 3',
  variable4: 'cvičná proměnná 4',
  variable5: 'cvičná proměnná 5',
  variable6: 'cvičná proměnná 6',
  variable7: 'cvičná proměnná 7',
  variable8: 'cvičná proměnná 8',
  variable9: 'cvičná proměnná 9',
  variable10: 'cvičná proměnná 10',

  propertyType: 'budova',
  propertyLv: '3002',
  propertyCadastralName: 'Malá Strana',
  propertyCity: 'Praha',
  propertyAddress: 'nábřeží Edvarda Beneše 128/4, Praha - Malá Strana, 118 00',
  propertyAreaNumber: '112/3',
  propertyName: 'rodinný dům - náb. E. Beneše 128, Praha',
}

export const deliveryMethodList = {
  [PostserviceLetterType.OrdinaryLetter]: {
    name: 'Obyčejné psaní',
    typvyplatneho: '100195',
    sluzby: '1k',
  },
  [PostserviceLetterType.Registered]: {
    name: 'Doporučené psaní',
    typvyplatneho: '100169',
    sluzby: '51+1k',
  },
  [PostserviceLetterType.OrdinaryLetterInternational]: {
    name: 'Obyčejné psaní do zahraničí',
    typvyplatneho: '315',
    sluzby: '',
  },
  [PostserviceLetterType.RegisteredInternational]: {
    name: 'Doporučené psaní do zahraničí',
    typvyplatneho: '311',
    sluzby: '53+9',
  },
}

export const recipientVariableName: {
  [key: string]: { [key: string]: string }
} = {
  Subjekt: {
    '[_uvedeni_]': 'Vážený/á pane/paní',
    '[_osloveni_]': 'Oslovení',
    /*     
    '[_titul_pred_]': 'Titul před',
    '[_titul_za_]': 'Titul za', */
    '[_krestni_jmeno_]': 'Křestní jméno',
    '[_prijmeni_]': 'Příjmení',
    '[_cele_jmeno_]': 'Celé jméno',
    '[_spolecnost_nazev_]': 'Název společnosti',
  },
  Adresa: {
    '[_ulice_nazev_]': 'Název ulice',
    '[_ulice_kompletni_]': 'Ulice s číslem',
    '[_obec_]': 'Obec',
    '[_psc_]': 'PSČ',
    '[_stat_]': 'Stát',
    '[_adresa_kompletni_]': 'Kompletní adresa',
  },
  'Vlastní proměnné': {
    '[_promenna_1_]': 'Proměnná 1',
    '[_promenna_2_]': 'Proměnná 2',
    '[_promenna_3_]': 'Proměnná 3',
    '[_promenna_4_]': 'Proměnná 4',
    '[_promenna_5_]': 'Proměnná 5',
    '[_promenna_6_]': 'Proměnná 6',
    '[_promenna_7_]': 'Proměnná 7',
    '[_promenna_8_]': 'Proměnná 8',
    '[_promenna_9_]': 'Proměnná 9',
    '[_promenna_10_]': 'Proměnná 10',
  },
  'Data o nemovitosti': {
    '[_nemo_nazev_]': 'Název/označení',
    '[_nemo_c_lv_]': 'Číslo LV',
    '[_nemo_kat_uzemi_]': 'Kat. území',
    '[_nemo_obec_]': 'Obec',
    '[_nemo_typ_]': 'Typ nemovitosti',
    '[_nemo_adresa_]': 'Adresa (budovy, jednotky)',
    '[_nemo_parcelni_c_]': 'Parcelní číslo (parcely)',
  },
}

export const letterImageRadioValue = {
  left: 'margin-right: auto; display: block',
  center: 'margin: 0 auto; display: block',
  right: 'margin-left: auto; display: block',
}

export const letterImageButtonStyles = {
  symbol: {
    color: 'inherit',
    cursor: 'default',
    width: 'auto',
    padding: '0 10px 0 10px',
  },
  label: { color: 'inherit', cursor: 'default', width: 'auto' },
}

export const imagePropsInitial: LetterImageProps = {
  width: 100,
  align: letterImageRadioValue.left,
  background: false,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
}

export interface LetterImageProps {
  width: number
  align: typeof letterImageRadioValue[keyof typeof letterImageRadioValue]
  background: boolean
  left?: number
  right?: number
  top?: number
  bottom?: number
}

export type Recipient = {
  id: string
  fyzickaosoba?: string | null
  krestnijmeno?: string | null
  osloveni?: string | null
  pohlavi?: string | null
  nazev?: string | null
  prijmeni?: string | null
  titulpred?: string | null
  titulza?: string | null
  addressByAdresnimistokod?: Address | null
}

export type RecipientList = {
  createdAt: string
  description?: Maybe<string>
  dispatchDate?: Maybe<string>
  id: string
  letterText?: Maybe<string>
  nazev: string
  recipientListRecipientsByRecipientListId: { nodes: Subject[] | null } | null
  senderAddressBySenderAddressId: SenderAddress
}

interface PropertyInfo {
  propertyId?: string
  propertyType?: PROPERTY_TYPES
  propertyLv?: string
  propertyCadastralCode?: string
  propertyCadastralName?: string
  propertyCity?: string
  propertyAddress?: string
  propertyAreaNumber?: string
  propertyName?: string
}

interface BaseRecipient {
  id: string
  companyName?: string
  firstname: string
  lastname: string
  callName?: string
  street: string
  houseNumber: string
  orientationNumber?: string
  city: string
  country: string
  zipCode: string
  variable1?: string
  variable2?: string
  variable3?: string
  variable4?: string
  variable5?: string
  variable6?: string
  variable7?: string
  variable8?: string
  variable9?: string
  variable10?: string
}

export interface PostServiceRecipient extends BaseRecipient, PropertyInfo {
  error?: string[]
  createdAt?: Date
  senderName?: string
  uniquePersonKey?: string
  addressId?: string
  gender?: 'f' | 'm' | '0'
}

export interface SentRecipient extends BaseRecipient, PropertyInfo {
  postserviceTrackingNumber?: string
  postserviceId?: string
  sentAt?: Date
  addressId?: string
}

export type Address = {
  castobce?: string | null
  cislodomovni?: number | string | null
  cisloorientacni?: string | null
  cisloorientacnipismeno?: string | null
  kraj?: string | null
  obec?: string | null
  okres?: string | null
  psc?: string | null
  radnice?: string | null
  typbudovy?: string | null
  ulice?: string | null
}

export type SenderAddress = {
  id: string
  senderName: string
  street: string
  orientationNumber?: string | null
  houseNumber?: string | null
  city: string
  zipCode: string
}

export interface Subject {
  id: string
  recipientByRecipientId: Recipient
  senderAddressBySenderAddressId: SenderAddress
  recipientListRecipientsByRecipientListId: { nodes: Subject[] | null } | null
}

export interface PostServiceAddRecipientFormValues {
  companyName?: string
  firstname: string
  lastname: string
  callName?: string
  gender?: 'f' | 'm' | 0 | '0'
  street: {
    streetName: string
    houseNumber: string
    orientationNumber?: string
  }
  city: string
  country: string
  senderName: string
  zipCode: string
  uniquePersonKey?: string
  addressId?: string
  variable1?: string
  variable2?: string
  variable3?: string
  variable4?: string
  variable5?: string
  variable6?: string
  variable7?: string
  variable8?: string
  variable9?: string
  variable10?: string
  property?: Partial<PostServiceLinkedProperty>
}

export interface PostServiceLinkedProperty {
  propertyId?: string
  propertyType?: PROPERTY_TYPES
  propertyLv?: string
  propertyCadastralCode?: string
  propertyCadastralName?: string
  propertyCity?: string
  propertyAddress?: string
  propertyAreaNumber?: string
  propertyName?: string
}

export interface SubjectsInPostServiceList {
  listName: string
  description?: string
  id: string
  recipientId: string
  labels: LabelConnectionFragmentFragment
}

export interface SubjectsInPostServiceLetter {
  id: string
  name: string
  description?: string
  sentAt?: Date
  postserviceType?: PostserviceLetterType
  postserviceId?: string
  trackingNumber?: string
  sentRecipientId: string
}

export interface SubjectsInPostService {
  list: SubjectsInPostServiceList[]
  letter: SubjectsInPostServiceLetter[]
}
