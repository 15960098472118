import { Button, message, Tooltip } from 'antd'
import axios from 'axios'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { CollectionType } from '../../../common/documentCollectionTypes'
import { useAppSelector } from '../../../redux/hooks'
import { requestPath } from '../../utils/paths'
import { useCheckClaims } from '../../utils/useCheckClaims'

export interface CuzkRegenerateFilesButtonProps {
  id: string
  collectionType: CollectionType
}

export const CuzkRegenerateFilesButton: FunctionComponent<CuzkRegenerateFilesButtonProps> =
  props => {
    const token = useAppSelector(state => state.myProfile.token)
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const tester = useCheckClaims('ApiVyvojar')

    const [loading, setLoading] = useState(false)

    const handleRegenerate = useCallback(async () => {
      const endpoint =
        props.collectionType === CollectionType.LV
          ? 'lvGenerate'
          : 'epoGenerate'

      setLoading(true)
      try {
        const url = requestPath(
          `cuzk/${endpoint}/${token}/${tenantId}/${props.id}`
        )
        const response = await axios.get(url)
        if (response.status >= 200 && response.status < 300) {
          message.success('Regenerace dokumentů proběhla')
        } else {
          message.error('Regenerace dokumentů selhala')
        }
      } catch (error) {
        message.error('Regenerace dokumentů selhala')
      } finally {
        setLoading(false)
      }
    }, [props.collectionType, props.id, token, tenantId])

    if (!tester) return null

    return (
      <Tooltip title="Tlačítko pouze pro ApiVyvojar. Slouží k regeneraci dokumentů z XML zdroje, pokud došlo k neočekávanému problému.">
        <Button
          type="primary"
          ghost
          danger
          loading={loading}
          onClick={handleRegenerate}
        >
          Regenerovat
        </Button>
      </Tooltip>
    )
  }
