import { FieldTimeOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { Menu } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  getItem,
  getSub,
  getSubExt,
  menuDivider,
  MenuItem,
} from '../../utils/menuHelpers'
import {
  addressPath,
  areaPath,
  auctionMapPath,
  auctionMonitoringPath,
  cadastreMapPath,
  ceeCheckPath,
  ceeMonitorPath,
  cuzkDocumentCollectionPath,
  cuzkEpoPath,
  cuzkLvPath,
  cuzkMonitorRcPath,
  dataBoxPath,
  foreclosureMapPath,
  foreclosureMonitoringPath,
  heritageMonitoringPath,
  homePath,
  insolvencyMapPath,
  insolvencyMonitoringPath,
  labelPath,
  lvPath,
  marketplacePath,
  paths,
  postServiceCreatePath,
  postServiceRecipientListPath,
  postServiceSentPath,
  postServiceTemplatesPath,
  priceEstimatePath,
  proceedingPath,
  propertyNotePath,
  statisticsPath,
  watchdogChannelPath,
  watchdogPropertyPath,
} from '../../utils/paths'
import { useCheckClaims } from '../../utils/useCheckClaims'
import { MainMenuItemTitle as Label } from '../MainMenuItemTitle/MainMenuItemTitle'

export const MainMenu: FunctionComponent = () => {
  const { pathname } = useLocation<{ pathname: string }>()
  const tester = useCheckClaims('ApiVyvojar')
  const admin = useCheckClaims('SpravciOrganizaci')
  const partner = useCheckClaims('Partner')
  const hasHeritage = useCheckClaims('MonitoringDedictvi')

  const matchedPaths = useMemo(() => {
    return paths
      .map(
        path =>
          matchPath(pathname, {
            path: path.path,
          })?.path || ''
      )
      .filter(path => !!path)
  }, [pathname])

  const items: MenuItem[] = useMemo(
    () => [
      {
        key: 'modulyDivider',
        label: menuDivider('Moduly'),
        type: 'group',
        children: [
          getItem(
            'Přehledy',
            'Přehledy',
            <Icon icon="fa:folder-o" width="13" />,
            [
              getItem(
                <Label sub title="Vývoj REGESTA 2.0" link={homePath()} />,
                'Regesta2.0'
              ),
              getSub('Poznámky', propertyNotePath()),
              getSub('Štítky', labelPath()),
            ]
          ),

          getItem(
            'Vyhledávání',
            'Vyhledávání',
            <Icon icon="fa:search" width="13" />,
            [
              getSub('Adresa', addressPath()),
              getSub('Parcela', areaPath()),
              getSub('List vlastnictví', lvPath()),
              getSub('Řízení', proceedingPath()),
              tester ? getSub('Datová schránka', dataBoxPath(), 'dev') : null,
              getSubExt('Subjekt', '/Vyhledavani/Subjekty'),
            ]
          ),

          getItem(
            <Label title="Katastrální mapa" link={cadastreMapPath()} />,
            cadastreMapPath(),
            <Icon icon="fa:map-o" width="14" />
          ),

          getItem(
            <Label title="Cenové odhady" link={priceEstimatePath()} />,
            priceEstimatePath(),
            <Icon icon="icon-park-outline:agreement" width="14" />
          ),

          tester
            ? getItem(
                <Label dev title="Marketplace" link={marketplacePath()} />,
                marketplacePath(),
                <Icon icon="map:real-estate-agency" width="14" />
              )
            : null,

          getItem('CEE', 'CEE', <FieldTimeOutlined />, [
            getSub('Vyhledat v CEE', ceeCheckPath()),
            getSub('Hlídat v CEE', ceeMonitorPath()),
          ]),

          getItem(
            <Label title="Hromadná pošta" />,
            'Hromadná pošta',
            <Icon icon="icon-park:envelope-one" width="15" />,
            [
              getSub('Přehled', postServiceSentPath()),
              getSub('Šablony', postServiceTemplatesPath()),
              getSub('Adresáti', postServiceRecipientListPath()),
              getSub('Rozeslat novou poštu', postServiceCreatePath('novy')),
            ]
          ),

          getItem(
            <Label title="Nem. v exekuci" />,
            'ForeclosureMonitoring',
            <Icon icon="fa:bomb" width="13" />,
            [
              getSub('Výpis', foreclosureMonitoringPath()),
              getSub('Mapa', foreclosureMapPath()),
            ]
          ),

          getItem(
            <Label title="Nem. v insolvenci" />,
            'InsolvencyMonitoring',
            <Icon icon="fa:map-o" width="14" />,
            [
              getSub('Výpis', insolvencyMonitoringPath()),
              getSub('Mapa', insolvencyMapPath()),
            ]
          ),

          hasHeritage
            ? getItem(
                <Label
                  title="Nem. v dědictví"
                  link={heritageMonitoringPath()}
                />,
                heritageMonitoringPath(),
                <Icon icon="fa:id-badge" width="12" />
              )
            : null,

          getItem(
            <Label title="Nem. v dražbě" />,
            'AuctionMonitoring',
            <Icon icon="fa:gavel" width="13" />,
            [
              getSub('Výpis', auctionMonitoringPath()),
              getSub('Mapa', auctionMapPath()),
            ]
          ),

          getItem(
            'Dálkový přístup',
            'DálkovýPřístup',
            <Icon icon="fa:file-text-o" width="12" />,
            [
              getSub('Výpis z listu vlastnictví', cuzkLvPath()),
              getSub('Evidence práv pro osobu', cuzkEpoPath()),
              getSub('Vyhledání osoby', cuzkMonitorRcPath()),
              getSub('Sbírka listin', cuzkDocumentCollectionPath()),
            ]
          ),

          getItem(
            <Label title="Hlídač katastru" />,
            'Watchdog',
            <Icon icon="material-symbols:notifications-outline" width={16} />,
            [
              getSub('Přehled nemovitostí', watchdogPropertyPath()),
              getSub('Kanály', watchdogChannelPath()),
            ]
          ),
        ],
      },

      partner
        ? {
            key: 'AdministraceDivider',
            label: menuDivider('Administrace'),
            type: 'group',
            children: [
              getItem(
                'Přehledy',
                'AdminPřehledy',
                <Icon icon="fa:users" width="13" />,
                [
                  getSubExt('Registované firmy', '/PortalAdmin/PrehledFirem'),
                  getSub('Statistiky využití', statisticsPath()),
                ]
              ),
            ],
          }
        : null,

      admin
        ? {
            key: 'organizaceDivider',
            label: menuDivider('Organizace'),
            type: 'group',
            children: [
              getItem(
                'SprávaOrganizace',
                'Správa organizace',
                <Icon icon="fa:users" width="13" />,
                [
                  getSubExt('Uživatelé', '/Sprava/Uzivatele'),
                  getSubExt('WSDP', '/Sprava/WSDP'),
                ]
              ),

              getItem(
                'Využití & útrata',
                'VyužitíUtrata',
                <Icon icon="fa-bar-chart" width={13} />,
                [
                  getSubExt('Položkový ceník', '/Cenik'),
                  getSubExt('Přehled', '/Vyuziti/Prehled'),
                  getSubExt('Statistika dotazů', '/Vyuziti/PrehledDrm'),
                  getSubExt('Výpis požadavků', '/Vyuziti/ApiPozadavky'),
                ]
              ),

              partner
                ? getItem(
                    'Obchodní sekce',
                    'ObchodníSekce',
                    <Icon icon="fa:users" width="13" />,
                    [
                      getSubExt('Slevové kódy', '/RegistracniKody'),
                      getItem(<a href="####">Nápověda </a>, 'help'),
                    ]
                  )
                : null,
            ],
          }
        : null,
    ],
    [admin, hasHeritage, partner, tester]
  )

  return (
    <Menu
      mode="inline"
      className="h-full"
      theme="light"
      items={items}
      defaultSelectedKeys={matchedPaths.length ? matchedPaths : undefined}
    />
  )
}
