import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { SentRecipient } from '../../../common/postServiceTypes'
import {
  RecipientFragment,
  usePostserviceSentLetterByIdQuery,
} from '../../../graphql/generated'
import { stringSorter } from '../../utils/generalTableHelpers'
import { letterRecipientsPagination } from '../../utils/layoutConst'
import {
  postServiceAddressRenderer,
  postServicePropertyRenderer,
  showVariableColumns,
} from '../../utils/postServiceHelpers'
import { PdfDrawerPreview } from '../PdfDrawerPreview/PdfDrawerPreview'
import { PostServiceLetterDetailPdf } from '../PostServiceLetterDetailPdf/PostServiceLetterDetailPdf'
import { PostServiceSentTableTrackingNumber } from '../PostServiceSentTableTrackingNumber/PostServiceSentTableTrackingNumber'

export interface PostServiceSentTableExpandedRow {
  letterId: string
}

export const PostServiceSentTableExpandedRow: FunctionComponent<PostServiceSentTableExpandedRow> =
  props => {
    const [data, setData] = useState<SentRecipient[] | undefined>([])

    const [queryResult] = usePostserviceSentLetterByIdQuery({
      variables: { id: props.letterId },
    })

    useEffect(() => {
      if (
        queryResult.data?.postserviceSentLetterById?.recipients?.nodes?.length
      ) {
        setData(
          queryResult.data?.postserviceSentLetterById?.recipients
            ?.nodes as SentRecipient[]
        )
      }
    }, [queryResult.data?.postserviceSentLetterById?.recipients?.nodes])

    const columns = useMemo<ColumnsType<SentRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Podací číslo',
          dataIndex: 'postserviceTrackingNumber',
          className: 'w-36',
          render: (trackingNumber, record) => (
            <PostServiceSentTableTrackingNumber
              postserviceId={record.postserviceId}
              trackingNumber={trackingNumber}
              sentAt={queryResult.data?.postserviceSentLetterById?.sentAt}
            />
          ),
        },
        {
          title: 'Společnost',
          dataIndex: 'companyName',
          sorter: stringSorter('companyName'),
        },
        {
          title: 'Jméno',
          dataIndex: 'recipientName',
          sorter: stringSorter('lastname'),
          render: (_, record) => record?.firstname + ' ' + record?.lastname,
        },
        {
          title: 'Korespondenční dresa',
          dataIndex: 'address',
          sorter: stringSorter('street'),
          render: postServiceAddressRenderer,
        },
        {
          title: 'Nemovitost',
          dataIndex: 'property',
          sorter: stringSorter('propertyName'),
          render: postServicePropertyRenderer,
        },
        ...showVariableColumns(data),
        {
          title: 'PDF',
          dataIndex: 'action',
          className: 'w-36',
          render: (_, record) => (
            <PdfDrawerPreview
              buttonProps={{ ghost: true, size: 'small' }}
              buttonText="Zobrazit dopis"
              drawerTitle="Náhled dopisu"
              fileName={record.lastname + '_HROMADNY_DOPIS_REGESTA_DATA'}
            >
              <PostServiceLetterDetailPdf
                data={record as RecipientFragment}
                letterText={
                  queryResult.data?.postserviceSentLetterById?.content || ''
                }
              />
            </PdfDrawerPreview>
          ),
        },
      ],
      [
        data,
        queryResult.data?.postserviceSentLetterById?.content,
        queryResult.data?.postserviceSentLetterById?.sentAt,
      ]
    )

    return (
      <Table
        pagination={letterRecipientsPagination}
        dataSource={data}
        columns={columns}
        rowKey="id"
        size="small"
        rowClassName="bg-[#fafafa]"
        className="mb-6"
      />
    )
  }
