import { WarningOutlined } from '@ant-design/icons'
import { Spin, Tooltip } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { usePropertiesLabels } from '../../utils/usePropertiesLabels'
import { InlineLabelsGroup } from '../InlineLabelsGroup/InlineLabelsGroup'
import { PropertyDetailEditLabelModal } from '../PropertyDetailEditLabelModal/PropertyDetailEditLabelModal'

export interface PropertyDetailLabelProps {
  propertyId: string
  propertyName: string
  propertyType: PROPERTY_TYPES
}

const buttonProps = {
  size: 'small',
  className: 'flex items-center px-2 mt-0.5',
} as const

const loadingSpin = (
  <Spin size="small" className="ml-2">
    Načítání štítků...
  </Spin>
)
const errorTooltip = (
  <Tooltip
    className="cursor-help text-red-500"
    title="Je nám líto, ale nepodařilo se načíst data o požadované nemovitosti."
  >
    <WarningOutlined /> Nemovitost nenalezena
  </Tooltip>
)

export const PropertyDetailLabel: FunctionComponent<PropertyDetailLabelProps> =
  props => {
    const { propertyLabels, loading, error, refetch } = usePropertiesLabels(
      [props.propertyId],
      props.propertyType || PROPERTY_TYPES.BUILDING
    )

    const { propertyData, labelConnections, labels } = useMemo(() => {
      const propertyLabelData = propertyLabels?.[props.propertyId]
      const propertyData = propertyLabelData?.propertyData
      const labelConnections =
        propertyLabelData?.labelConnectionsByPropertyLabelId?.nodes || []
      const labels = labelConnections.map(label => label?.labelByLabelId)

      return { propertyData, labelConnections, labels }
    }, [propertyLabels, props.propertyId])

    if (loading) return loadingSpin
    if (error) return errorTooltip

    return (
      <div className="flex space-x-2">
        <InlineLabelsGroup labels={labels} />
        <PropertyDetailEditLabelModal
          buttonText={labels.length ? 'Upravit štítky' : 'Přidat štítek'}
          buttonProps={buttonProps}
          labelConnections={labelConnections}
          refetch={refetch}
          propertyData={propertyData}
          propertyId={props.propertyId}
          propertyType={props.propertyType}
        />
      </div>
    )
  }
