import { WarningOutlined } from '@ant-design/icons'
import { Breakpoint, Spin, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { Budova, Parcela } from '../../../common/lvTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  compareParcelNumbers,
  nestedStringSorter,
  numberSorter,
  stringSorter,
} from '../../utils/generalTableHelpers'
import {
  priceEstimateSummaryStyle,
  propertyDetailTableProps,
} from '../../utils/layoutConst'
import { metrageProps } from '../../utils/propertyDetailHelper'
import { useDeclension } from '../../utils/useDeclension'
import { usePropertiesLabels } from '../../utils/usePropertiesLabels'
import { watchdogBuildingColumn } from '../../utils/watchdogHelpers'
import { Money } from '../Money/Money'
import { PropertyDetailLink } from '../PropertyDetailLink/PropertyDetailLink'
import { PropertyDetailOwnershipRestrictionCell as RestrictionCell } from '../PropertyDetailOwnershipRestrictionCell/PropertyDetailOwnershipRestrictionCell'
import { PropertyTableItemLabel } from '../PropertyTableItemLabel/PropertyTableItemLabel'

export interface PropertyDetailLvBuildingTableProps {
  budovy: Budova[]
  parcely?: Parcela[]
  propertyId: string
  showCity?: boolean
  hideMetrage?: boolean
  drawerTarget?: boolean
  target?: React.HTMLAttributeAnchorTarget
}

export const PropertyDetailLvBuildingTable: FunctionComponent<PropertyDetailLvBuildingTableProps> =
  props => {
    const decline = useDeclension()
    const lvGeometry = useAppSelector(state => state.property.lvGeometry)

    const buildingsIds = useMemo(
      () => props.budovy.map(budova => budova.id),
      [props.budovy]
    )

    const {
      propertyLabels,
      refetch,
      loading: labelsLoading,
    } = usePropertiesLabels(buildingsIds, PROPERTY_TYPES.BUILDING)

    const dataSource = useMemo(() => {
      if (lvGeometry.fetching || !lvGeometry.data?.budovy) {
        return props.budovy
      }

      const lvBudovyMap = new Map(
        lvGeometry.data.budovy.map(budova => [budova.id, budova])
      )

      return props.budovy.map(budova => {
        const lvBudova = lvBudovyMap.get(budova.id)
        return {
          ...budova,
          zastavenaPlocha: lvBudova?.zastavenaPlocha ?? budova.zastavenaPlocha,
        }
      })
    }, [lvGeometry.data?.budovy, lvGeometry.fetching, props.budovy])

    const buildingRenderer = useCallback(
      (building: string, record: Budova) => (
        <PropertyDetailLink
          text={building}
          propertyId={record.id}
          propertyType={PROPERTY_TYPES.BUILDING}
          isActive={record.id === props.propertyId}
          drawerTarget={props.drawerTarget}
          target={props.target}
        />
      ),
      [props.drawerTarget, props.propertyId, props.target]
    )

    const areaRenderer = useCallback(
      (parcela: Parcela) => {
        if (!parcela?.id) return null

        const isPartOfOtherProperty = !props.parcely?.some(
          p => p.id === parcela.id
        )
        const parcelNumber = parcela.parcelniCislo || 'Bez parc.č.'
        const text = (
          <>
            {parcelNumber}
            {isPartOfOtherProperty && (
              <Tooltip
                className="pl-2"
                title="Parcela pod budovou je součástí jiného listu vlastnictví. Kliknutím přejdete na její detail."
              >
                <WarningOutlined className="text-red-500" />
              </Tooltip>
            )}
          </>
        )
        return (
          <PropertyDetailLink
            text={text}
            propertyId={parcela.id}
            propertyType={PROPERTY_TYPES.AREA}
            drawerTarget={props.drawerTarget}
            target={props.target}
          />
        )
      },
      [props.drawerTarget, props.parcely, props.target]
    )

    const labelSorter = useCallback(
      (a: Budova, b: Budova) =>
        (
          propertyLabels?.[b.id]?.labelConnectionsByPropertyLabelId?.nodes?.[0]
            ?.labelByLabelId?.name || ''
        ).localeCompare(
          propertyLabels?.[a.id]?.labelConnectionsByPropertyLabelId?.nodes?.[0]
            ?.labelByLabelId?.name || ''
        ),
      [propertyLabels]
    )

    const labelRenderer = useCallback(
      (propertyId: string) =>
        labelsLoading ? (
          <Spin size="small" />
        ) : (
          <PropertyTableItemLabel
            propertyId={propertyId}
            propertyType={PROPERTY_TYPES.BUILDING}
            refetch={refetch}
            propertyLabel={propertyLabels?.[propertyId]}
          />
        ),
      [labelsLoading, propertyLabels, refetch]
    )

    const buildingColumns: ColumnsType<Budova> = useMemo(
      () => [
        {
          title: 'Budova',
          dataIndex: 'zpusobVyuziti',
          className: 'max-w-xs',
          sorter: stringSorter('zpusobVyuziti'),
          render: buildingRenderer,
        },
        {
          title: 'Štítky',
          className: 'max-w-xs',
          dataIndex: 'id',
          key: 'labels',
          sorter: labelSorter,
          render: labelRenderer,
        },
        {
          title: 'Obec',
          dataIndex: 'castObce',
          className: 'w-32',
          responsive: props.showCity ? undefined : ['' as Breakpoint],
          sorter: nestedStringSorter('castObce', 'nazev'),
          render: castObce => castObce?.nazev || '',
        },
        {
          title: 'Číslo budovy',
          dataIndex: 'cislaDomovni',
          ellipsis: true,
          className: 'w-32',
          sorter: stringSorter('cislaDomovni'),
          render: cislaDomovni => cislaDomovni || 'Bez č.p.',
        },
        {
          title: 'Na parcele',
          dataIndex: 'idetifikacniParcela',
          className: 'w-32',
          sorter: (a, b) =>
            compareParcelNumbers(
              a.idetifikacniParcela?.parcelniCislo || '',
              b.idetifikacniParcela?.parcelniCislo || ''
            ),
          render: areaRenderer,
        },
        {
          title: 'Omezení vl. práv',
          dataIndex: 'omezeniVlPrav',
          render: omezeni => <RestrictionCell restriction={omezeni} />,
        },
        {
          title: 'Zastavěno',
          dataIndex: 'zastavenaPlocha',
          className: 'w-28 !text-right',
          showSorterTooltip: {
            title: 'Zastavěná plocha je vypočtena z polygonu budovy.',
          },
          responsive: props.hideMetrage ? ['' as Breakpoint] : undefined,
          sorter: numberSorter('zastavenaPlocha'),
          render: vymera => <Money amount={vymera} {...metrageProps} />,
        },
        watchdogBuildingColumn,
      ],
      [
        buildingRenderer,
        labelSorter,
        labelRenderer,
        props.showCity,
        props.hideMetrage,
        areaRenderer,
      ]
    )

    const summary = useCallback(
      () =>
        dataSource?.length > 1 && (
          <Table.Summary.Row>
            <Table.Summary.Cell
              className="text-gray-400 !border-b-0 border-gray-100 border-t"
              index={1}
              colSpan={2}
            >
              Celkem: {decline(dataSource.length, 'building', true)}
            </Table.Summary.Cell>
            {props.hideMetrage ? null : (
              <Table.Summary.Cell
                className={priceEstimateSummaryStyle}
                index={2}
                colSpan={4}
              >
                Celkem:{' '}
                <Money
                  {...metrageProps}
                  amount={dataSource.reduce(
                    (a, b) => a + Number(b.zastavenaPlocha),
                    0
                  )}
                />
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        ),
      [dataSource, decline, props.hideMetrage]
    )

    const rowClassName = useCallback(
      (record: Budova) =>
        record.id === props.propertyId ? 'text-gray-400' : '',
      [props.propertyId]
    )

    return (
      <Table
        dataSource={dataSource}
        columns={buildingColumns}
        rowClassName={rowClassName}
        rowKey="id"
        summary={summary}
        {...propertyDetailTableProps}
      />
    )
  }
