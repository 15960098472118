import { Alert, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { BASE_URL } from '../../../../ini.json'
import { EpoTableItem, RIZENIType } from '../../../common/epoTypes'
import { Rizeni } from '../../../common/proceedingTypes'
import { useAppSelector } from '../../../redux/hooks'
import {
  nestedStringSorter,
  stringSorter,
} from '../../utils/generalTableHelpers'
import { tablePagination } from '../../utils/layoutConst'
import { lvDetailPath } from '../../utils/paths'
import { parseProceedingString } from '../../utils/proceedingDetailHelpers'
import { CuzkEpoDetailStopFetching } from '../CuzkEpoDetailStopFetching/CuzkEpoDetailStopFetching'
import { CuzkEpoXlsxButton } from '../CuzkEpoXlsxButton/CuzkEpoXlsxButton'
import { MapTooltip } from '../MapTooltip/MapTooltip'
import { Money } from '../Money/Money'
import { ProceedingDetailModalLink } from '../ProceedingDetailModalLink/ProceedingDetailModalLink'
import { TableItemsCount } from '../TableItemsCount/TableItemsCount'

export interface CuzkEpoDetailTableProps {
  epoId: string
  lvCount?: number | null
  isHugeSubject: boolean
}

const rowKey = (record: EpoTableItem) =>
  (record.KATUZE?.kod || '') + (record.LV || record.JPV_LV)

const calculateSharePercentage = (record: EpoTableItem): number => {
  if (record.PODIL?.citatel && record.PODIL?.jmenovatel) {
    return (record.PODIL.citatel / (record.PODIL.jmenovatel || 1)) * 100
  } else if (record.LV) {
    return 100
  }
  return 0
}

const proceedingSorter = (a: EpoTableItem, b: EpoTableItem) => {
  const aRizeni = a.RIZENI
  const bRizeni = b.RIZENI
  if (aRizeni && bRizeni) {
    const maxARizeni = aRizeni.reduce(
      (max, current) =>
        current.rok && current.rok.localeCompare(max) > 0 ? current.rok : max,
      ''
    )
    const maxBRizeni = bRizeni.reduce(
      (max, current) =>
        current.rok && current.rok.localeCompare(max) > 0 ? current.rok : max,
      ''
    )
    return maxARizeni.localeCompare(maxBRizeni)
  }
  return -1
}

const tableId = 'cuzkEpoDetail-table'

export const CuzkEpoDetailTable: FunctionComponent<CuzkEpoDetailTableProps> =
  props => {
    const tenantId = useAppSelector(state => state.myProfile.tenantId)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [dataSource, setDataSource] = useState<EpoTableItem[]>()
    const [stopFetching, setStopFetching] = useState(!!props.isHugeSubject)

    useEffect(() => {
      if (dataSource || stopFetching) return
      setLoading(true)

      fetch(`${BASE_URL}/download/${tenantId}/${props.epoId}.json`)
        .then(response => response.text())
        .then(data => {
          setLoading(false)
          setDataSource(JSON.parse(data))
        })
        .catch(() => {
          setLoading(false)
          setError(true)
        })
    }, [dataSource, props.epoId, stopFetching, tenantId])

    const columns = useMemo<ColumnsType<EpoTableItem>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Typ oprávnění',
          dataIndex: 'TYPRAV_NAZEV',
          sorter: stringSorter('TYPRAV_NAZEV'),
        },
        {
          title: 'Okres',
          dataIndex: 'OKRES',
          sorter: stringSorter('OKRES'),
        },
        {
          title: 'Obec',
          dataIndex: 'OBEC',
          sorter: stringSorter('OBEC'),
        },
        {
          title: 'Katastrální území',
          dataIndex: 'KATUZE',
          sorter: nestedStringSorter('KATUZE', 'nazev'),
          render: (katuze, record) => (
            <MapTooltip query={record.OBEC + ' ' + katuze?.nazev}>
              {katuze?.nazev}
            </MapTooltip>
          ),
        },
        {
          title: 'LV',
          dataIndex: 'LV',
          render: (LV, record) =>
            (LV || record.JPV_LV) && (
              <Link
                title="Přejít na detail LV v novém okně"
                target="_blank"
                to={lvDetailPath(record.KATUZE?.kod || '', LV || record.JPV_LV)}
              >
                {LV || record.JPV_LV}
              </Link>
            ),
        },
        {
          title: 'Podíl',
          dataIndex: 'PODIL',
          className: 'text-right',
          render: (podil, record) => {
            const percentage = calculateSharePercentage(record)

            return (
              <div>
                {podil?.citatel && podil?.jmenovatel ? (
                  <>
                    <span>
                      {podil.citatel} / {podil.jmenovatel || 1}
                    </span>
                    <span className="pl-2 text-gray-400 inline-block w-20">
                      <Money amount={percentage} symbol="%" />
                    </span>
                  </>
                ) : record.LV ? (
                  <span className="pl-2 text-gray-400 inline-block w-20">
                    <Money amount={percentage} symbol="%" />
                  </span>
                ) : null}
              </div>
            )
          },
          sorter: {
            compare: (a, b) =>
              calculateSharePercentage(a) - calculateSharePercentage(b),
          },
        },
        {
          title: 'Řízení',
          dataIndex: 'RIZENI',
          render: rizeni =>
            rizeni?.map((item: RIZENIType, index: number) => (
              <ProceedingDetailModalLink
                key={index}
                className="block"
                proceeding={
                  parseProceedingString(
                    `${item.typriz_kod}-${item.poradove_cislo}/${item.rok}-${item.prares_kod}`
                  ) as Rizeni
                }
              >
                {`${item.typriz_kod}-${item.poradove_cislo}/${item.rok}`}
              </ProceedingDetailModalLink>
            )),
          sorter: {
            compare: proceedingSorter,
          },
        },
      ],
      []
    )

    const locale = useMemo(
      () => ({
        emptyText: !loading && (
          <div className="my-8">
            <div className="mb-2 text-gray-400">
              Pro vyhledaný subjekt nebyly nalezeny v evidenci práv ČÚZK žádné
              záznamy.
            </div>
          </div>
        ),
      }),
      [loading]
    )

    const summary = useCallback(
      () =>
        props.lvCount ? (
          <Table.Summary.Row className="bg-[#fafafa]">
            <Table.Summary.Cell colSpan={6} index={0} className="!py-2">
              <TableItemsCount total={dataSource?.length || 0} />
            </Table.Summary.Cell>
            <Table.Summary.Cell className="text-left !py-2" index={1}>
              <CuzkEpoXlsxButton
                buttonText="Exportovat do XLSX"
                buttonProps={{ type: 'link' }}
                id={props.epoId}
              />
            </Table.Summary.Cell>
          </Table.Summary.Row>
        ) : undefined,
      [dataSource?.length, props.epoId, props.lvCount]
    )

    if (error)
      return (
        <Alert
          message="Chyba dat"
          description="Nepodařilo se načíst data z ČÚZK."
          type="error"
          className="my-8"
          showIcon
        />
      )

    if (stopFetching)
      return (
        <CuzkEpoDetailStopFetching
          lvCount={props.lvCount}
          epoId={props.epoId}
          setStopFetching={setStopFetching}
        />
      )

    if (loading)
      return (
        <div className="my-8 flex flex-col justify-center align-middle bg-white">
          <Spin spinning />
          <div className="mb-2 mt-2 text-center text-gray-400">
            Načítají se data z ČÚZK...
          </div>
        </div>
      )

    if (!dataSource) return null

    return (
      <Table
        id={tableId}
        rowKey={rowKey}
        dataSource={[...dataSource]}
        size="small"
        columns={columns}
        loading={loading}
        locale={locale}
        pagination={tablePagination}
        summary={summary}
      />
    )
  }
