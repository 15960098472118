import { useMemo } from 'react'

import { PostServiceLinkedProperty } from '../../common/postServiceTypes'
import { useAppSelector } from '../../redux/hooks'
import { usePropertyDetailTitle } from './usePropertyDetailTitle'

export const usePostServicePropertyFromRedux = () => {
  const ruianData = useAppSelector(state => state.property.ruian.data)
  const drmData = useAppSelector(state => state.property.drm.data)
  const propertyName = usePropertyDetailTitle()

  return useMemo<Partial<PostServiceLinkedProperty>>(
    () => ({
      propertyType: ruianData?.type,
      propertyName: ruianData?.type ? propertyName : undefined,
      propertyCity: ruianData?.obec?.nazev,
      propertyId: ruianData?.id,
      propertyAddress:
        ruianData && 'adresniMista' in ruianData
          ? ruianData.adresniMista?.[0]?.formatovanaAdresa
          : undefined,
      propertyLv: `${drmData?.lv}`,
      propertyCadastralName: `${drmData?.katastralniUzemi?.nazev}`,
      propertyCadastralCode: `${drmData?.katastralniUzemi?.kod}`,
    }),
    [
      drmData?.katastralniUzemi?.kod,
      drmData?.katastralniUzemi?.nazev,
      drmData?.lv,
      propertyName,
      ruianData,
    ]
  )
}
