import { z } from 'zod'

export const AuctionListingSchema = z.object({
  slug: z.string(),

  auctionNumber: z.string().optional(),
  ruianId: z.string().optional(),

  title: z.string(),
  description: z.string(),

  price: z.number().nonnegative(),

  lat: z.number().min(48.6).max(51.1).optional(),
  long: z.number().min(12.1).max(18.9).optional(),

  detailUrl: z.string().url(),
  sourceHostname: z.enum([
    'portaldrazeb.cz',
    'nabidkamajetku.cz',
    'okdrazby.cz',
    'exdrazby.cz',
    'drazby-exekutori.cz',
    'e-drazby.cz',
    'cevd.gov.cz',
  ]),

  auctionedOn: z.string().optional(),

  auctionDecreeUrl: z.string().url().optional(),
  auctionDecreeText: z.string().optional(),

  type: z.enum(['area', 'building', 'unit', 'unknown']),

  state: z.enum(['upcoming', 'ongoing', 'ended']),

  startDate: z.string().datetime().optional(),
  // There are auctions which are 'ended' but without endDate
  endDate: z.string().datetime().optional(),

  photoUrl: z.string().url().optional(),

  parentId: z.string().optional(),

  createdAt: z.string().datetime().optional(),
})

export type AuctionListing = z.infer<typeof AuctionListingSchema>

export type AuctionListingState = AuctionListing['state']

export type AuctionListingType = AuctionListing['type']

export enum AUCTION_TYPES {
  AREA = 'area',
  BUILDING = 'building',
  UNIT = 'unit',
  UNKNOWN = 'unknown',
}

export enum AUCTION_STATES {
  UPCOMING = 'upcoming',
  ONGOING = 'ongoing',
  ENDED = 'ended',
}
