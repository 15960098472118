import { Select } from 'antd'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { RuianQuery } from '../../util/RuianQuery'
import { Overlay, OverlayProps } from '../CadastreMapOverlay/CadastreMapOverlay'

export interface CadastreMapOkresControlProps extends OverlayProps {
  value: string | undefined
  onChange: (okres: string) => void
}

export const CadastreMapOkresControl: FunctionComponent<CadastreMapOkresControlProps> =
  props => {
    const [options, setOptions] = useState<
      { key: string; label: string; value: string }[]
    >([])
    useEffect(() => {
      RuianQuery.Okres.returnGeometry(false)
        .outFields(['objectid', 'nazev'])
        .asGeoJson()
        .then(featureCollection =>
          setOptions(
            featureCollection.features
              .map(feature => ({
                key: feature.properties.objectid.toString(),
                label: feature.properties.nazev,
                value: feature.properties.objectid.toString(),
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          )
        )
    }, [])
    return (
      <Overlay position={props.position}>
        {() => (
          <div className="flex space-x-2">
            <Select
              placeholder="Vyberte okres"
              style={{ minWidth: 183 }}
              options={options}
              value={props.value}
              allowClear
              onChange={value => {
                props.onChange(value)
              }}
            />
          </div>
        )}
      </Overlay>
    )
  }
