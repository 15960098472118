import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import { PROPERTY_TAB_KEYS } from '../../common/propertyDetailTypes'
import { RuianBuildingResponse } from '../../common/ruianTypes'
import { UserActivityActionType } from '../../graphql/generated'
import { useAppSelector } from '../../redux/hooks'
import { useCheckClaims } from './useCheckClaims'
import { useFetchAddressAres } from './useFetchAddressAres'
import { useFetchDrmSubjects } from './useFetchDrmSubjects'
import { useFetchPropertyCrmDrm } from './useFetchPropertyCrmDrm'
import { useFetchPropertyCrmTimeline } from './useFetchPropertyCrmTimeline'
import { useFetchPropertyDrm } from './useFetchPropertyDrm'
import { useFetchPropertyLv } from './useFetchPropertyLv'
import { useFetchPropertyLvGeometry } from './useFetchPropertyLvGeometry'
import { useFetchPropertyRisy } from './useFetchPropertyRisy'
import { useFetchPropertyRuian } from './useFetchPropertyRuian'
import { useFetchPropertyTimeline } from './useFetchPropertyTimeline'
import { useFetchRzpSubjects } from './useFetchRzpSubjects'
import { useFetchWatchdogProperty } from './useFetchWatchdog'
import { activityEntityType } from './userActivityLogHelpers'
import { useUpdateUserActivityLog } from './useUpdateUserActivityLog'

export const useFetchPropertyDetail = (
  propertyId?: string,
  propertyType?: PROPERTY_TYPES
) => {
  const location = useLocation()
  // TODO: sanitize for Regesta 1.0 paths; could be removed after production release
  const propertyTypeSanitized = propertyType?.toLowerCase() as PROPERTY_TYPES

  const prevParams = useRef({ propertyType: propertyTypeSanitized, propertyId })
  const fetchStateRef = useRef({
    dataFetched: false,
    risyFetched: false,
    aresFetched: false,
    lastFetchDate: '',
  })

  const hasCrmClaim = useCheckClaims('CrmRegestaPremium')
  const hasCrmId = useAppSelector(state => !!state.myProfile.regestaCrm)
  const isCrmEnabled = hasCrmClaim && hasCrmId

  const lastFetchDate = useAppSelector(
    state => state.property.drm.data?.utcAktualizace
  )
  const ruianDetail = useAppSelector(
    state => state.property.ruian?.data as RuianBuildingResponse
  )

  const fetchRuian = useFetchPropertyRuian()

  const fetchCrmDrm = useFetchPropertyCrmDrm()
  const fetchCrmTimeline = useFetchPropertyCrmTimeline()

  const fetchDrm = useFetchPropertyDrm()
  const fetchPropertyTimeline = useFetchPropertyTimeline()

  const fetchLv = useFetchPropertyLv()
  const fetchLvGeometry = useFetchPropertyLvGeometry()

  const fetchDrmSubjects = useFetchDrmSubjects()
  const fetchRzpSubjects = useFetchRzpSubjects()
  const fetchAddressAres = useFetchAddressAres()
  const fetchPropertyRisy = useFetchPropertyRisy()

  const fetchWatchdog = useFetchWatchdogProperty()
  const updateUserActivityLog = useUpdateUserActivityLog()

  const activeTab = useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get('tab')
  }, [location.search])

  const handleSubjects = useCallback(async () => {
    const addressId = ruianDetail?.adresniMista?.[0]?.kod
    if (!addressId || fetchStateRef.current.aresFetched) return

    fetchStateRef.current.aresFetched = true
    fetchDrmSubjects(addressId)
    const rzpResponse = await fetchRzpSubjects(addressId)
    fetchAddressAres({ start: 0, pocet: 1000, ico: rzpResponse?.icoList })
  }, [
    fetchAddressAres,
    fetchDrmSubjects,
    fetchRzpSubjects,
    ruianDetail?.adresniMista,
  ])

  const handleRisy = useCallback(async () => {
    const { kod, nazev } = ruianDetail?.obec || { kod: '', nazev: '' }
    if (!kod || !nazev || fetchStateRef.current.risyFetched) return

    fetchStateRef.current.risyFetched = true
    fetchPropertyRisy(kod, nazev)
  }, [fetchPropertyRisy, ruianDetail?.obec])

  const fetchDataWithCrmId = useCallback(async () => {
    if (!propertyId || !propertyTypeSanitized) return
    try {
      const drmData = await fetchCrmDrm(propertyId, propertyTypeSanitized)
      if (drmData && drmData.lv && drmData.cadastralCode) {
        fetchStateRef.current.lastFetchDate = drmData.updateDate || ''
        await Promise.all([
          fetchLv(drmData.cadastralCode, drmData.lv),
          fetchLvGeometry(drmData.cadastralCode, drmData.lv),
          fetchCrmTimeline(propertyId, propertyTypeSanitized),
        ])
      }
    } catch (error) {
      console.error(
        'Některá data pro detail nemovitosti nejsou dostupná',
        error
      )
    }
  }, [
    fetchCrmDrm,
    fetchLv,
    fetchLvGeometry,
    fetchCrmTimeline,
    propertyId,
    propertyTypeSanitized,
  ])

  const fetchDataWithoutCrmId = useCallback(async () => {
    if (!propertyId || !propertyTypeSanitized) return
    try {
      const drmData = await fetchDrm(propertyId, propertyTypeSanitized)
      if (drmData && drmData.lv && drmData.cadastralCode) {
        fetchStateRef.current.lastFetchDate = 'now'
        await Promise.all([
          fetchLv(drmData.cadastralCode, drmData.lv),
          fetchLvGeometry(drmData.cadastralCode, drmData.lv),
          fetchPropertyTimeline(propertyId, propertyTypeSanitized),
        ])
      }
    } catch (error) {
      console.error(
        'Některá data pro detail nemovitosti nejsou dostupná',
        error
      )
    }
  }, [
    fetchDrm,
    fetchLv,
    fetchLvGeometry,
    fetchPropertyTimeline,
    propertyId,
    propertyTypeSanitized,
  ])

  const initialFetchData = useCallback(async () => {
    if (!propertyId || !propertyTypeSanitized) return
    try {
      const ruianPromise = fetchRuian(propertyId, propertyTypeSanitized)

      if (isCrmEnabled) {
        fetchDataWithCrmId()
      } else {
        fetchDataWithoutCrmId()
      }

      await ruianPromise

      fetchWatchdog()
      updateUserActivityLog(
        propertyId,
        activityEntityType[propertyTypeSanitized],
        UserActivityActionType.SeenDetail
      )
    } catch (error) {
      console.error(
        'Některá data pro detail nemovitosti nejsou dostupná',
        error
      )
    }
  }, [
    fetchDataWithCrmId,
    fetchDataWithoutCrmId,
    fetchRuian,
    fetchWatchdog,
    isCrmEnabled,
    propertyId,
    propertyTypeSanitized,
    updateUserActivityLog,
  ])

  useEffect(() => {
    if (
      prevParams.current.propertyType !== propertyTypeSanitized ||
      prevParams.current.propertyId !== propertyId
    ) {
      fetchStateRef.current.dataFetched = false
      fetchStateRef.current.risyFetched = false
      fetchStateRef.current.aresFetched = false
      fetchStateRef.current.lastFetchDate = ''
    }

    prevParams.current = { propertyType: propertyTypeSanitized, propertyId }
  }, [propertyId, propertyTypeSanitized])

  useEffect(() => {
    if (activeTab === PROPERTY_TAB_KEYS.SUBJECT) handleSubjects()
    if (activeTab === PROPERTY_TAB_KEYS.RISY) handleRisy()
    if (activeTab === PROPERTY_TAB_KEYS.PRICE_ESTIMATE) handleRisy()
  }, [activeTab, handleRisy, handleSubjects])

  useEffect(() => {
    if (!fetchStateRef.current.dataFetched) {
      fetchStateRef.current.dataFetched = true
      initialFetchData()
    } else if (
      //refetch data when updated
      isCrmEnabled &&
      !!lastFetchDate &&
      lastFetchDate !== fetchStateRef.current.lastFetchDate
    ) {
      fetchDataWithCrmId()
    }
  }, [fetchDataWithCrmId, isCrmEnabled, initialFetchData, lastFetchDate])

  return [
    fetchStateRef.current.dataFetched,
    (value: boolean) => {
      fetchStateRef.current.dataFetched = value
    },
  ] as const
}
