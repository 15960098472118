import { Button, ButtonProps, Modal } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PostServiceRecipient } from '../../../common/postServiceTypes'
import { useToggleState } from '../../utils/useToggleState'
import { PostServiceAddRecipientForm } from '../PostServiceAddRecipientForm/PostServiceAddRecipientForm'

export interface PostServiceAddRecipientModalButton {
  listName: string
  listId: string
  buttonProps?: ButtonProps
  buttonText?: string
  record?: PostServiceRecipient
  reexecuteQuery?: () => void
}

export const PostServiceAddRecipientModalButton: FunctionComponent<PostServiceAddRecipientModalButton> =
  props => {
    const [showHelp, toggleShowHelp] = useToggleState()
    const [open, toggleModal] = useToggleState()

    const modalTitle = useMemo(() => {
      if (!props.record)
        return (
          <span>
            Přidat adresáta do seznamu{' '}
            <span className="italic">{props.listName}</span>
          </span>
        )
      return 'Editace adresáta'
    }, [props.record, props.listName])

    const initialValues = useMemo(() => {
      if (!props.record)
        return {
          country: 'CZ',
          gender: 0 as const,
        }
      return {
        ...props.record,
        gender: props.record.gender || (0 as const),
        street: {
          streetName: props.record.street,
          houseNumber: props.record.houseNumber,
          orientationNumber: props.record.orientationNumber,
        },
      }
    }, [props.record])

    return (
      <>
        <Button
          type="primary"
          onClick={toggleModal}
          size="small"
          className="sm:w-auto w-full"
          {...props.buttonProps}
        >
          {props.buttonText || 'Přidat adresáta do seznamu'}
        </Button>
        <Modal
          title={modalTitle}
          open={open}
          onCancel={toggleModal}
          footer={null}
          width={600}
        >
          <PostServiceAddRecipientForm
            listId={props.listId}
            listName={props.listName}
            hideModal={toggleModal}
            handleHelpToggle={toggleShowHelp}
            showHelp={showHelp}
            initialValues={initialValues}
            reexecuteQuery={props.reexecuteQuery}
          />
        </Modal>
      </>
    )
  }
