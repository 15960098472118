import { useEffect, useState } from 'react'

import { EsriField, EsriFieldType } from '../types/esri'
import { RuianLayer } from '../types/RuianLayers'

type ComponentType =
  | 'InputNumber'
  | 'Select'
  | 'MultiSelect'
  | 'Input'
  | 'Checkbox'
  | 'InputDate'

function determineComponentType(field: EsriField): ComponentType {
  const type = (() => {
    switch (field.type) {
      case EsriFieldType.OID:
      case EsriFieldType.Integer:
      case EsriFieldType.SmallInteger:
        return field.domain ? 'MultiSelect' : 'InputNumber'
      case EsriFieldType.Double:
        return 'InputNumber'
      case EsriFieldType.String:
        return field.length === 1 ? 'Checkbox' : 'Input'
      case EsriFieldType.Date:
        return 'InputDate'
      default:
        return field.domain ? 'Select' : null
    }
  })()
  if (type === null) throw new Error(`Unsupported field type: ${field.type}`)
  return type
}

const cache = new Map<RuianLayer, EsriField[]>()

async function fetchArcGisLayerFields(
  layerId: RuianLayer
): Promise<EsriField[]> {
  let fields: EsriField[] = cache.get(layerId) || []
  if (fields.length) return fields
  const url = new URL('https://ags.cuzk.cz')
  url.pathname = `/arcgis/rest/services/RUIAN/Prohlizeci_sluzba_nad_daty_RUIAN/MapServer/${layerId}`
  url.searchParams.append('f', 'json')
  fields = await fetch(url)
    .then(res => res.json())
    .then((res: { fields: EsriField[] }) => res.fields)
  cache.set(layerId, fields)
  return fields
}

export function useRuianField(
  ruianLayerId: RuianLayer,
  fieldName: string
): [EsriField | null, ComponentType | null] {
  const [fieldData, setFieldData] = useState<EsriField | null>(null)
  const [componentType, setComponentType] = useState<ComponentType | null>(null)

  useEffect(() => {
    fetchFieldData()
    async function fetchFieldData() {
      try {
        const fields = await fetchArcGisLayerFields(ruianLayerId)
        const matchedField = fields.find(field => field.name === fieldName)
        if (matchedField) {
          setFieldData(matchedField)
          setComponentType(determineComponentType(matchedField))
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [ruianLayerId, fieldName])

  return [fieldData, componentType]
}
