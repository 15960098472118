import React, { FunctionComponent } from 'react'
import { Layer } from 'react-map-gl'

import { useCadastreMapTheme } from './hooks/useCadastreMapTheme'

export interface CadastreMapBuildingLayersProps {
  clustering: boolean
  enabled: boolean

  pointsSource: string
  clusterSource: string

  filteredSource: string
  unfilteredSource: string
}

export const CadastreMapBuildingLayers: FunctionComponent<CadastreMapBuildingLayersProps> =
  ({
    clustering,
    enabled,
    pointsSource,
    clusterSource,
    filteredSource: fillSource,
    unfilteredSource: linesSource,
  }) => {
    const [theme] = useCadastreMapTheme()

    return (
      <>
        <Layer
          id="building-cluster-circle"
          source={clusterSource}
          type="circle"
          filter={['has', 'point_count']}
          layout={{
            visibility: clustering && enabled ? 'visible' : 'none',
          }}
          paint={{
            'circle-color': theme.buildingClusterCircleFill,
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              150,
              40,
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': theme.buildingClusterCircleOutline,
          }}
        />
        <Layer
          id="building-cluster-text"
          source={clusterSource}
          type="symbol"
          filter={['has', 'point_count']}
          layout={{
            visibility: clustering && enabled ? 'visible' : 'none',
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
          }}
          paint={{
            'text-color': theme.buildingClusterText,
          }}
        />
        <Layer
          id="building-circle"
          source={clusterSource}
          type="circle"
          filter={['!', ['has', 'point_count']]}
          layout={{
            visibility: clustering && enabled ? 'visible' : 'none',
          }}
          paint={{
            'circle-color': theme.buildingClusterCircleFill,
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': theme.buildingClusterCircleOutline,
          }}
        />
        <Layer
          id="building-lines"
          source={linesSource}
          type="line"
          layout={{ visibility: enabled ? 'visible' : 'none' }}
          paint={{
            'line-color': theme.buildingOutline,
            'line-width': 1,
          }}
          minzoom={15}
        />
        <Layer
          id="building-fill"
          source={fillSource}
          type="fill"
          layout={{ visibility: enabled ? 'visible' : 'none' }}
          paint={{
            'fill-color': theme.buildingFill,
          }}
          minzoom={15}
        />
        <Layer
          id="building-points"
          source={pointsSource}
          type="circle"
          layout={{
            visibility: !clustering && enabled ? 'visible' : 'none',
          }}
          paint={{
            'circle-color': theme.buildingPointFill,
            'circle-radius': 3,
            'circle-stroke-width': 1,
            'circle-stroke-color': theme.buildingPointOutline,
          }}
        />
      </>
    )
  }
