import { TeamOutlined } from '@ant-design/icons'
import * as turf from '@turf/turf'
import { formatDistance } from 'date-fns'
import { cs } from 'date-fns/locale'
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  Point,
} from 'geojson'
import React, {
  ComponentProps,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import { Popup, useMap } from 'react-map-gl'

import { CadastreMapDebtFilterFormOutput } from '../../../common/cadastreMapDebtTypes'
import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ZpusobVyuziti } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { drmPropertyType } from '../../utils/ruianHelpers'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'
import { CadastreMapDebtLayers } from './CadastreMapDebtLayers'
import { CadastreMapDebtSource } from './CadastreMapDebtSource'
import { CadastreMapDebtLayerControl } from './controlls/CadastreMapDebtLayerControl/CadastreMapDebtLayerControl'
import { RuianLayer } from './types/RuianLayers'
import { RuianQuery } from './util/RuianQuery'

export interface CadastreMapDebtDisplayProps {
  setHighlightedFeatures: Dispatch<
    SetStateAction<FeatureCollection<Geometry, GeoJsonProperties>>
  >
}

export const CadastreMapDebtDisplay: FunctionComponent<CadastreMapDebtDisplayProps> =
  props => {
    const [debtEnabled, setDebtEnabled] = useState(true)
    const [debtFilterParams, setDebtFilterParams] = useState<
      CadastreMapDebtFilterFormOutput | undefined
    >(undefined)

    type PopupProps = {
      id: string
      typ: typeof drmPropertyType[keyof typeof drmPropertyType]
      zpusobVyuziti: ZpusobVyuziti
      posledniVkladZapis: string
      pocetVlastniku: number
    }
    const [popups, setPopups] = useState<Feature<Point, PopupProps[]>[]>([])

    const setDrawer = useSetPropertyDrawer()

    const token = useAppSelector(state => state.myProfile.token)
    const handlePopupRowOnClick = useCallback(
      async (popupProps: PopupProps) => {
        const { id, typ } = popupProps
        const url = `https://data.regesta.cz/DrmApi/api/v1.0/Nemovitosti/${typ}/${id}/Ruian?access_token=${token}`
        const ruianKod = await fetch(url)
          .then(response => response.json())
          .then(
            data => data?.kod ?? data?.[0]?.kod ?? data?.[0]?.id ?? data?.id
          )
          .catch(() => undefined)
        if (typ === 'Budova') {
          const ruianBudova = await RuianQuery.StavebniObjekt.where(
            `kod = ${ruianKod}`
          )
            .asGeoJson()
            .then(fc => {
              fc.features = fc.features.map(feature => {
                return {
                  ...feature,
                  properties: {
                    ...feature.properties,
                    layerId: RuianLayer.STAVEBNI_OBJEKT,
                    layerName: 'StavebniObjekt',
                  },
                }
              })
              return fc
            })
          props.setHighlightedFeatures(ruianBudova)
        } else if (typ === 'Parcela') {
          const ruianParcela = await RuianQuery.Parcela.where(
            `id = ${ruianKod}`
          )
            .asGeoJson()
            .then(fc => {
              fc.features = fc.features.map(feature => {
                return {
                  ...feature,
                  properties: {
                    ...feature.properties,
                    layerId: RuianLayer.PARCELA,
                    layerName: 'Parcela',
                  },
                }
              })
              return fc
            })
          props.setHighlightedFeatures(ruianParcela)
        } else if (typ === 'Jednotka') {
          setDrawer(ruianKod, PROPERTY_TYPES.UNIT)
        }
      },
      [token, props, setDrawer]
    )

    const map = useMap()
    const handleOnData = useCallback<
      NonNullable<ComponentProps<typeof CadastreMapDebtSource>['onData']>
    >(
      data => {
        if (
          !data ||
          !data.features ||
          !map ||
          !map.current ||
          map.current.getZoom() < 13
        ) {
          setPopups([])
          return
        }
        const popups = (data?.features ?? [])
          .filter(feature => feature.properties?.detaily)
          .map(feature => {
            const geometry = feature.geometry as Point
            const detaily = feature.properties?.detaily ?? []
            return turf.point(geometry.coordinates, detaily)
          })
        setPopups(popups)
      },
      [map]
    )

    return (
      <>
        {popups.map((popup, index) => (
          <Popup
            key={index}
            longitude={popup.geometry.coordinates[0]}
            latitude={popup.geometry.coordinates[1]}
            closeButton={false}
            anchor="bottom-right"
            maxWidth={undefined}
          >
            <div className="space-y-1 -mb-1.5">
              {popup.properties.map((property, index) => (
                <div
                  key={index}
                  onClick={() => handlePopupRowOnClick(property)}
                  className="flex justify-start items-center space-x-2 text-gray-800 text-sm cursor-pointer hover:text-blue-500"
                >
                  <div>{property.pocetVlastniku}</div>
                  <TeamOutlined title="Vlastníci" />
                  <div>{property?.zpusobVyuziti?.nazev ?? property.typ}</div>
                  <div
                    title={new Date(
                      property.posledniVkladZapis
                    ).toLocaleDateString()}
                  >
                    {formatDistance(
                      new Date(property.posledniVkladZapis),
                      new Date(),
                      { addSuffix: true, locale: cs }
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Popup>
        ))}
        <CadastreMapDebtLayerControl
          enabled={debtEnabled}
          onEnabledChange={enabled => setDebtEnabled(enabled)}
          onFilterParams={filterParams => {
            setDebtEnabled(true)
            setDebtFilterParams(filterParams)
          }}
        />
        <CadastreMapDebtSource
          id="debt"
          filterParams={debtFilterParams}
          onData={handleOnData}
        />
        <CadastreMapDebtLayers enabled={debtEnabled} source="debt" />
      </>
    )
  }
