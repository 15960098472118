import React, { FunctionComponent } from 'react'

import { MAPBOX_ACCESS_TOKEN } from '../../../ini.json'
import { CadastreMap } from '../components/CadastreMap/CadastreMap'
import { CadastreMapProvider } from '../components/CadastreMap/CadastreMapProvider'
import { PropertyDetailDrawer } from '../components/PropertyDetailDrawer/PropertyDetailDrawer'

export const ForeclosureMap: FunctionComponent = () => {
  return (
    <>
      <div className="relative flex flex-1 flex-col -m-4">
        <CadastreMapProvider>
          <CadastreMap showDebt mapboxAccessToken={MAPBOX_ACCESS_TOKEN} />
        </CadastreMapProvider>
      </div>
      <PropertyDetailDrawer />
    </>
  )
}
