import { message } from 'antd'
import { HTMLElement } from 'node-html-parser'
import { Delta } from 'quill'

import { Recipient } from './postServiceTypes'

export type RecipientWithProperties =
  | {
      id: string
      propertyName?: string | null
      propertyType?: string | null
      recipientByRecipientId?: Recipient | null
    }
  | null
  | undefined

export const addHtmlContainer = (line: string) => {
  return `<html><body><style>
  dl, dt, dd, ol, ul, li {padding-left: 16px;}
  body {font-family: 'OpenSans'; font-size: 12px; position: relative; padding: 0 } 
  p {margin-top: 0px; margin-bottom: 0px; font-size: 12px}
  h1 {margin-bottom: 12px; font-size: 24px } 
  h2 {margin-bottom: 0px; font-size: 20px } 
  h3 {margin-bottom: 0px; font-size: 16px } 
  img {max-width: 100%}
  blockquote {margin-left:0; padding-left: 48px; border-left: 10px solid #dddddd;} 
  .ql-indent-1 {padding-left: 24px;} 
  .ql-indent-2 {padding-left: 48px;} 
  .ql-indent-3 {padding-left: 72px;} 
  .ql-indent-4 {padding-left: 96px;} 
  .ql-size-small {font-size: 10px;} 
  .ql-size-large {font-size: 16px;} 
  .ql-size-huge {font-size: 20px;} 
  .ql-align-right {text-align: right;} 
  .ql-align-left {text-align: left;} 
  .ql-align-center {text-align: center;} 
  .ql-align-justify {text-align: justify;} 
  .ql-align-right img {align-self: flex-end;} 
  .ql-align-center img {align-self: center;}
  .ql-font-emitha-script {font-family: "emitha-script"; font-size: 40px;}
  </style>${line}</body></html>`
}

export const styleSupSub = {
  sup: {
    verticalAlign: 'super',
    fontSize: 10,
  },
  sub: {
    verticalAlign: 'sub',
    fontSize: 10,
  },
}

export const replaceVariables = (
  line: string,
  recipient: RecipientWithProperties,
  withHtml?: boolean
) => {
  const greeting =
    recipient?.recipientByRecipientId?.pohlavi === 'm'
      ? 'Vážený pane'
      : recipient?.recipientByRecipientId?.pohlavi === 'f'
      ? 'Vážená paní'
      : 'Vážený pane, vážená paní'
  const subject = recipient?.recipientByRecipientId
  const address = recipient?.recipientByRecipientId?.addressByAdresnimistokod

  const streetComponents = [
    address?.ulice,
    address?.cislodomovni,
    address?.cisloorientacni ? '/' + address?.cisloorientacni : '',
    address?.cisloorientacnipismeno || '',
  ]
  const street = streetComponents.join(' ').trim()
  const fullAddress = street + ' ' + address?.obec + ' ' + address?.psc

  const callName = subject?.osloveni || subject?.prijmeni || ''
  const content = withHtml ? line : addHtmlContainer(line)

  return content
    .replace('[subjekt_uvedeni]', greeting || '')
    .replace('[subjekt_oslovení]', callName || '')
    .replace('[subjekt_titul_pred]', subject?.titulpred || '')
    .replace('[subjekt_titul_za]', subject?.titulza || '')
    .replace('[subjekt_jmeno]', subject?.krestnijmeno || '')
    .replace('[subjekt_prijmeni]', subject?.prijmeni || '')
    .replace('[subjekt_nazev]', subject?.nazev || '')

    .replace('[adresa_ulice_nazev]', address?.ulice || '')
    .replace('[adresa_ulice]', street)
    .replace('[adresa_obec]', address?.obec || '')
    .replace('[adresa_psc]', address?.psc || '')
    .replace('[adresa_okres]', address?.okres || '')
    .replace('[adresa_kompletní]', fullAddress)
}

export const replacePostVariables = (
  line: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recipient: any,
  withHtml?: boolean
) => {
  const greeting =
    recipient?.gender === 'm'
      ? 'Vážený pane'
      : recipient?.gender === 'f'
      ? 'Vážená paní'
      : 'Vážený pane, vážená paní'

  const streetComponents = [
    recipient?.street,
    recipient?.houseNumber,
    recipient?.orientationNumber ? '/' + recipient?.orientationNumber : '',
  ]
  const fullStreet = streetComponents.join(' ').trim()
  const fullName = recipient?.firstname + ' ' + recipient?.lastname
  const fullAddress =
    fullStreet +
    ' ' +
    recipient?.city +
    ' ' +
    recipient?.zipCode +
    ' ' +
    recipient?.country

  const callName = recipient?.callName || recipient?.lastname || ''
  const content = withHtml ? line : addHtmlContainer(line)

  return (
    content
      .replace(/\[_uvedeni_\]/g, greeting || '')
      .replace(/\[_osloveni_\]/g, callName || '')
      /*   
      .replace(/\[_titul_pred_\]/g, recipient?.titulpred || '')
      .replace(/\[_titul_za_\]/g, recipient?.titulza || '') 
      */
      .replace(/\[_krestni_jmeno_\]/g, recipient?.firstname || '')
      .replace(/\[_prijmeni_\]/g, recipient?.lastname || '')
      .replace(/\[_cele_jmeno_\]/g, fullName || '')
      .replace(/\[_spolecnost_nazev_\]/g, recipient?.companyName || '')

      .replace(/\[_ulice_nazev_\]/g, recipient?.street || '')
      .replace(/\[_ulice_kompletni_\]/g, fullStreet || '')
      .replace(/\[_obec_\]/g, recipient?.city || '')
      .replace(/\[_psc_\]/g, recipient?.zipCode || '')
      .replace(/\[_stat_\]/g, recipient?.country || '')
      .replace(/\[_adresa_kompletni_\]/g, fullAddress || '')

      .replace(/\[_promenna_1_\]/g, recipient?.variable1 || '')
      .replace(/\[_promenna_2_\]/g, recipient?.variable2 || '')
      .replace(/\[_promenna_3_\]/g, recipient?.variable3 || '')
      .replace(/\[_promenna_4_\]/g, recipient?.variable4 || '')
      .replace(/\[_promenna_5_\]/g, recipient?.variable5 || '')
      .replace(/\[_promenna_6_\]/g, recipient?.variable6 || '')
      .replace(/\[_promenna_7_\]/g, recipient?.variable7 || '')
      .replace(/\[_promenna_8_\]/g, recipient?.variable8 || '')
      .replace(/\[_promenna_9_\]/g, recipient?.variable9 || '')
      .replace(/\[_promenna_10_\]/g, recipient?.variable10 || '')

      .replace(/\[_nemo_nazev_\]/g, recipient?.propertyName || '')
      .replace(/\[_nemo_c_lv_\]/g, recipient?.propertyLv || '')
      .replace(/\[_nemo_kat_uzemi_\]/g, recipient?.propertyCadastralName || '')
      .replace(/\[_nemo_obec_\]/g, recipient?.propertyCity || '')
      .replace(/\[_nemo_typ_\]/g, recipient?.propertyType || '')
      .replace(/\[_nemo_adresa_\]/g, recipient?.propertyAddress || '')
      .replace(/\[_nemo_parcelni_c_\]/g, recipient?.propertyAreaNumber || '')
  )
}

export const checkAndCleanImages = (htmlString: string) => {
  const imgRegex = /<img[^>]+src="([^">]+)"/g
  const maxBase64Size = 5 * 1048576 // 5 MB v Base64

  let totalBase64Length = 0
  let cleanedHtml = htmlString
  let match
  let alertCalled = false

  while ((match = imgRegex.exec(htmlString)) !== null) {
    const imgTag = match[0]
    const imgSrc = match[1]

    if (!imgSrc.startsWith('data:image')) {
      cleanedHtml = cleanedHtml.replace(imgTag, '')
      if (!alertCalled) {
        message.error(
          'Přiložené soubory mohou být pouze obrázky (.jpg, .gif, .png).',
          5
        )
        alertCalled = true
      }
    } else {
      const base64Data = imgSrc.split(',')[1]
      totalBase64Length += base64Data.length

      if (totalBase64Length > maxBase64Size) {
        cleanedHtml = cleanedHtml.replace(imgTag, '')
        if (!alertCalled) {
          message.error(
            'Obrázky přesahují maximální velikost 5 MB, česká pošta by takovou přílohu nepřijala.',
            5
          )
          alertCalled = true
        }
      }
    }
  }
  return cleanedHtml
}

const retainEmithaScriptClassMatcher = (
  node: HTMLElement,
  delta: Delta | null
) => {
  if (node?.classList && node.classList.contains('ql-font-emitha-script')) {
    const newOps = delta?.ops?.map(op => {
      if (op?.insert && typeof op?.insert === 'string')
        return {
          insert: op.insert,
          attributes: { ...op?.attributes, font: 'emitha-script' },
        }
      return op
    })
    return { ...delta, ops: newOps }
  }
  return delta
}

export const quillProps = {
  placeholder: 'Napište obsah dopisu, nebo vyberte šablonu.',
  theme: 'snow',
  formats: [
    'bold',
    'italic',
    'underline',
    'strike',
    'size',
    'blockquote',
    'paragraph',
    'align',
    'list',
    'script',
    'color',
    'background',
    'clean',
    'indent',
    'header',
    'image',
    'font',
    'formula',
  ],
  modules: {
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
    clipboard: {
      matchers: [[1, retainEmithaScriptClassMatcher]],
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      ['image'],
      [{ align: [] }],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      ['clean'],
      [{ font: 'emitha-script' }],
      [{ formula: 'formula' }],
    ],
  },
}
