import { DotChartOutlined, GoldOutlined } from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import { Map } from 'mapbox-gl'
import React, { FunctionComponent, useCallback } from 'react'
import { MapProps } from 'react-map-gl'

import { CadastreMapBuildingLayerControlFilterModalButton } from '../../../CadastreMapBuildingLayerControlFilterModalButton/CadastreMapBuildingLayerControlFilterModalButton'
import { RuianQueryInputGeometry } from '../../util/RuianQuery'
import { Overlay, OverlayProps } from '../CadastreMapOverlay/CadastreMapOverlay'

export interface CadastreMapBuildingLayerControlProps extends OverlayProps {
  initialViewState: Required<
    Pick<
      NonNullable<MapProps['initialViewState']>,
      'zoom' | 'latitude' | 'longitude'
    >
  >
  onWhereChange: (where: string) => void

  enabled: boolean
  onEnabledChange: React.Dispatch<React.SetStateAction<boolean>>

  cluster: boolean
  onClusterChange: React.Dispatch<React.SetStateAction<boolean>>

  geometryFilter?: RuianQueryInputGeometry | null

  loading: boolean
}

export const CadastreMapBuildingLayerControl: FunctionComponent<CadastreMapBuildingLayerControlProps> =
  props => {
    const toggleEnabled = useCallback(
      () => props.onEnabledChange(prev => !prev),
      [props]
    )

    const toggleCluster = useCallback(
      () => props.onClusterChange(prev => !prev),
      [props]
    )

    const handleConfirm = useCallback(
      (map: Map) => {
        map.setCenter([
          props.initialViewState.longitude,
          props.initialViewState.latitude,
        ])
        map.setZoom(props.initialViewState.zoom)
      },
      [props.initialViewState]
    )

    return (
      <Overlay position={props.position}>
        {({ map }) => (
          <div className="flex space-x-2">
            <Button
              loading={props.loading}
              onClick={toggleEnabled}
              className="hover:!bg-white"
              title="Zobrazit vybrané budovy"
              icon={<Checkbox checked={props.enabled} rootClassName="!mr-2" />}
            >
              Budovy
            </Button>

            <CadastreMapBuildingLayerControlFilterModalButton
              onWhereChange={props.onWhereChange}
              onConfirm={() => handleConfirm(map)}
              geometryFilter={props.geometryFilter}
            />

            <Button
              icon={props.cluster ? <DotChartOutlined /> : <GoldOutlined />}
              onClick={toggleCluster}
              className="hover:!bg-white"
              title="Zobrazit body/klastry"
            />
          </div>
        )}
      </Overlay>
    )
  }
