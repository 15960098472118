import * as turf from '@turf/turf'
import { message } from 'antd'
import { FeatureCollection, Point } from 'geojson'
import { useEffect, useState } from 'react'

import { RuianQuery, RuianQueryInputGeometry } from '../util/RuianQuery'

export function useParcelPoints(
  where: string,
  shouldFetch: boolean,
  geometryFilter?: RuianQueryInputGeometry | null
): [boolean, FeatureCollection<Point>] {
  const [loading, setLoading] = useState(false)
  const [parcels, setParcels] = useState(turf.featureCollection<Point>([]))

  useEffect(() => {
    fetchParcelPoints()
    async function fetchParcelPoints() {
      try {
        setLoading(true)
        if (!shouldFetch) return
        if (!where || where === '') {
          setParcels(turf.featureCollection([]))
          return
        }
        let query = RuianQuery.ParcelaDefinicniBod.outFields([
          'objectid',
        ]).where(where)
        if (geometryFilter) {
          query = query.layerInputGeometry(geometryFilter)
        }
        setParcels(await query.asGeoJson())
      } catch (error) {
        console.error(error)
        message.error('Nepodařilo se načíst parcelní body')
        setParcels(turf.featureCollection([]))
      } finally {
        setLoading(false)
      }
    }
    // Not really sure, but if I pass shouldFetch and even if I exit early it causes expensive re-fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where, geometryFilter])

  return [loading, parcels]
}
