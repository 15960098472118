import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Breakpoint } from 'antd/lib/_util/responsiveObserver'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { PostServiceRecipient } from '../../../common/postServiceTypes'
import { usePostserviceRecipientTableQuery } from '../../../graphql/generated'
import {
  stringSorter,
  utcDescDateSorter,
} from '../../utils/generalTableHelpers'
import { defaultPagination } from '../../utils/layoutConst'
import {
  postServiceAddressRenderer,
  postServicePropertyRenderer,
  showVariableColumns,
} from '../../utils/postServiceHelpers'
import { Min } from '../DateFormat/DateFormat'
import { ExportTableXlsxButton } from '../ExportTableXlsxButton/ExportTableXlsxButton'
import { PostServiceAddRecipientModalButton } from '../PostServiceAddRecipientModalButton/PostServiceAddRecipientModalButton'
import { PostServiceImportRecipientModalButton } from '../PostServiceImportRecipientModalButton/PostServiceImportRecipientModalButton'
import { PostServiceRecipientListDeleteButton } from '../PostServiceRecipientListDeleteButton/PostServiceRecipientListDeleteButton'

export interface PostServiceRecipientListTableExpandedRow {
  listId: string
  listName: string
  refetch: () => void
}

const buttonProps = { type: 'primary' as const, ghost: true }

export const PostServiceRecipientListTableExpandedRow: FunctionComponent<PostServiceRecipientListTableExpandedRow> =
  props => {
    const [data, setData] = useState<PostServiceRecipient[] | undefined>()

    const [queryResult, refetch] = usePostserviceRecipientTableQuery({
      variables: { id: props.listId },
    })

    useEffect(() => {
      if (
        queryResult.data?.postserviceListById
          ?.postserviceRecipientsByPostserviceListId?.nodes?.length
      ) {
        setData(
          queryResult.data?.postserviceListById
            ?.postserviceRecipientsByPostserviceListId
            ?.nodes as PostServiceRecipient[]
        )
      }
    }, [
      queryResult.data?.postserviceListById
        ?.postserviceRecipientsByPostserviceListId?.nodes,
    ])

    const reexecuteQuery = useCallback(() => {
      refetch()
      props.refetch()
    }, [props, refetch])

    const columns = useMemo<ColumnsType<PostServiceRecipient>>(
      () => [
        {
          title: 'ID',
          dataIndex: 'id',
          responsive: ['' as Breakpoint],
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'createdAt',
          sorter: utcDescDateSorter('createdAt'),
          render: createdAt => <Min>{createdAt}</Min>,
        },
        {
          title: 'Společnost',
          dataIndex: 'companyName',
          sorter: stringSorter('companyName'),
        },
        {
          title: 'Jméno',
          dataIndex: 'recipientName',
          sorter: stringSorter('lastname'),
          render: (_, record) => record?.firstname + ' ' + record?.lastname,
        },
        {
          title: 'Korespondenční dresa',
          dataIndex: 'address',
          sorter: stringSorter('street'),
          render: postServiceAddressRenderer,
        },
        {
          title: 'Nemovitost',
          dataIndex: 'property',
          sorter: stringSorter('propertyName'),
          render: postServicePropertyRenderer,
        },
        ...showVariableColumns(data),
        {
          title: 'Akce',
          dataIndex: 'action',
          key: 'action',
          className: 'w-36',
          render: (_, record) => (
            <div className="flex space-x-2">
              <PostServiceAddRecipientModalButton
                listId={props.listId}
                listName={props.listName}
                record={record}
                reexecuteQuery={reexecuteQuery}
                buttonProps={{
                  type: 'primary',
                  size: 'small',
                  ghost: true,
                }}
                buttonText="Editovat"
              />
              <PostServiceRecipientListDeleteButton
                record={record}
                reexecuteQuery={reexecuteQuery}
              />
            </div>
          ),
        },
      ],
      [data, props.listId, props.listName, reexecuteQuery]
    )

    return (
      <div className="mb-6 text-right">
        <Table
          pagination={defaultPagination}
          dataSource={data}
          columns={columns}
          rowKey="id"
          size="small"
          rowClassName="bg-[#fafafa]"
          className="mb-6"
        />
        <div className="flex justify-end space-x-2">
          <ExportTableXlsxButton
            url="postService/recipientListXlsx"
            fileName="seznam_adresatu"
            dataSource={data || []}
            icon
          />
          <PostServiceImportRecipientModalButton
            buttonProps={buttonProps}
            listId={props.listId}
          />
          <PostServiceAddRecipientModalButton
            listId={props.listId}
            listName={props.listName}
          />
        </div>
      </div>
    )
  }
