import { Form, Input, InputNumber, Select } from 'antd'
import React, { FunctionComponent, useCallback } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { postServiceFormHelp } from '../../utils/cuzkMonitorRcFormHelp'
import { itemHelp } from '../../utils/formHelpers'
import { Fade } from '../Fade/Fade'
import { SearchCadastralArea } from '../SearchCadastralArea/SearchCadastralArea'

interface PostServiceAddRecipientFormPropertyFragmentProps {
  showHelp: boolean
}

const { Option } = Select

export const PostServiceAddRecipientFormPropertyFragment: FunctionComponent<PostServiceAddRecipientFormPropertyFragmentProps> =
  ({ showHelp }) => {
    const form = Form.useFormInstance()
    const propertyType = Form.useWatch('propertyType', form)
    const cadastreCode = form.getFieldValue('propertyCadastralCode')

    const handleCadastreCodeChange = useCallback(
      (data: {
        katastralniUzemiKod?: string
        katastralniUzemiNazev?: string
        obecNazev?: string
      }) => {
        form.setFieldsValue({
          propertyCity: data.obecNazev,
          propertyCadastralCode: data.katastralniUzemiKod,
          propertyCadastralName: data.katastralniUzemiNazev,
        })
      },
      [form]
    )

    return (
      <>
        <Fade show={showHelp}>{postServiceFormHelp.propertyInfo}</Fade>

        <Form.Item
          label="Kat. území"
          name="propertyCadastralCode"
          help={itemHelp(showHelp, postServiceFormHelp.propertyCadastreArea)}
        >
          <SearchCadastralArea
            initialValue={cadastreCode}
            setCadastralData={handleCadastreCodeChange}
            autoFocus={false}
          />
        </Form.Item>

        <Form.Item
          label="Číslo LV"
          name="propertyLv"
          help={itemHelp(showHelp, postServiceFormHelp.propertyLvNumber)}
        >
          <InputNumber
            className="!w-full"
            placeholder="Číslo listu vlastnictví"
            max="99999"
            stringMode
          />
        </Form.Item>

        <Form.Item
          label="Obec"
          name="propertyCity"
          help={itemHelp(showHelp, postServiceFormHelp.propertyCity)}
        >
          <Input placeholder="Obec" />
        </Form.Item>

        <Form.Item label="Typ nemovitosti" name="propertyType">
          <Select
            className="w-full"
            allowClear
            placeholder="Vyberte typ nemovitosti"
          >
            <Option value={PROPERTY_TYPES.AREA}>Parcela</Option>
            <Option value={PROPERTY_TYPES.BUILDING}>Budova</Option>
            <Option value={PROPERTY_TYPES.UNIT}>Jednotka</Option>
          </Select>
        </Form.Item>

        {(propertyType === PROPERTY_TYPES.BUILDING ||
          propertyType === PROPERTY_TYPES.UNIT) && (
          <Form.Item
            label="Adresa"
            name="propertyAddress"
            help={itemHelp(showHelp, postServiceFormHelp.propertyAddress)}
          >
            <Input placeholder="Adresa budovy" />
          </Form.Item>
        )}

        {propertyType === PROPERTY_TYPES.AREA && (
          <Form.Item
            label="Parcelní číslo"
            name="propertyAreaNumber"
            help={itemHelp(showHelp, postServiceFormHelp.propertyAreaNumber)}
          >
            <Input placeholder="Kmenové číslo / díl parcely" />
          </Form.Item>
        )}

        <Form.Item
          label="Název nemovitosti"
          name="propertyName"
          help={itemHelp(showHelp, postServiceFormHelp.propertyName)}
        >
          <Input placeholder="Označení nemovitosti" />
        </Form.Item>

        <Form.Item name="propertyId" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="propertyCadastralName" hidden>
          <Input />
        </Form.Item>
      </>
    )
  }
