import { Spin } from 'antd'
import React, { FunctionComponent, useMemo } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { TABLE_VIEW } from '../../../common/foreclosureMonitoringTypes'
import { MonitoringInsolvenciFetchResult } from '../../../common/insolvencyMonitoringTypes'
import { useUserActivityLogCheck } from '../../utils/useCheckUserActivityLog'
import { usePropertiesLabels } from '../../utils/usePropertiesLabels'
import { usePropertyTableNoteCheck } from '../../utils/usePropertyTableNoteCheck'
import { InsolvencyMonitoringCompactTable } from '../InsolvencyMonitoringCompactTable/InsolvencyMonitoringCompactTable'
import { InsolvencyMonitoringTable } from '../InsolvencyMonitoringTable/InsolvencyMonitoringTable'
import { InsolvencyMonitoringTiles } from '../InsolvencyMonitoringTiles/InsolvencyMonitoringTiles'
import { PropertyMonitoringFetchError } from '../PropertyMonitoringFetchError/PropertyMonitoringFetchError'

export interface InsolvencyMonitoringContentProps {
  viewType: string | number
  propertyType: PROPERTY_TYPES
  dataSource?: MonitoringInsolvenciFetchResult
}

export const InsolvencyMonitoringContent: FunctionComponent<InsolvencyMonitoringContentProps> =
  props => {
    const propertyIds = useMemo(
      () => props.dataSource?.data?.data?.map(item => item.id) || [],
      [props.dataSource]
    )
    const userActivityLogs = useUserActivityLogCheck(propertyIds)
    const propertyNotes = usePropertyTableNoteCheck(propertyIds)
    const { propertyLabels, loading: labelsLoading } = usePropertiesLabels(
      propertyIds,
      props.propertyType
    )

    if (props.dataSource?.error)
      return <PropertyMonitoringFetchError type="insolvencí" />

    return (
      <div className="w-full">
        <Spin spinning={props.dataSource?.fetching}>
          {props.viewType === TABLE_VIEW.TILES && (
            <InsolvencyMonitoringTiles
              data={props.dataSource?.data?.data}
              propertyType={props.propertyType}
              propertiesActivity={userActivityLogs}
              propertyNotes={propertyNotes}
              propertyLabels={labelsLoading ? null : propertyLabels}
            />
          )}
          {props.viewType === TABLE_VIEW.TABLE && (
            <InsolvencyMonitoringTable
              data={props.dataSource?.data?.data}
              propertyType={props.propertyType}
              userActivityLogs={userActivityLogs}
              propertyNotes={propertyNotes}
              propertyLabels={labelsLoading ? 'loading' : propertyLabels}
            />
          )}
          {props.viewType === TABLE_VIEW.COMPACT_TABLE && (
            <InsolvencyMonitoringCompactTable
              data={props.dataSource?.data?.data}
              propertyType={props.propertyType}
              propertiesActivity={userActivityLogs}
              propertyNotes={propertyNotes}
              propertyLabels={labelsLoading ? null : propertyLabels}
            />
          )}
        </Spin>
      </div>
    )
  }
