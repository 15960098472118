import 'mapbox-gl/dist/mapbox-gl.css'

import * as turf from '@turf/turf'
import { Feature, Geometry, Point } from 'geojson'
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Map, {
  FullscreenControl,
  GeoJSONSource,
  GeolocateControl,
  Layer,
  LayerProps,
  MapProps,
  MapRef,
  Source,
} from 'react-map-gl'

import { useConst } from '../../utils/useConst'
import { CadastreMapAuctionDisplay } from '../CadastreMapAuctionDisplay/CadastreMapAuctionDisplay'
import { GeocoderControl } from '../CadastreMapGeocoderControl/CadastreMapGeocoderControl'
import { CadastreMapAdministrationDisplay } from './CadastreMapAdministrationDisplay'
import { CadastreMapBaseParcelRasterDisplay } from './CadastreMapBaseParcelRasterDisplay'
import { CadastreMapBuildingLayers } from './CadastreMapBuildingLayers'
import { CadastreMapDebtDisplay } from './CadastreMapDebtDisplay'
import { CadastreMapDetailDrawer } from './CadastreMapDetailDrawer'
import { CadastreMapHighlightIcon } from './CadastreMapHighlightIcon'
import { CadastreMapInsolvencyDisplay } from './CadastreMapInsolvencyDisplay'
import { CadastreMapParcelLayers } from './CadastreMapParcelLayers'
import { CadastreMapRadonDisplay } from './CadastreMapRadonDisplay'
import { CadastreMapRuianArcGisSource } from './CadastreMapRuianArcGisSource'
import { CadastreMapSelectedFeatureDisplay } from './CadastreMapSelectedFeatureDisplay'
import { CadastreMapSpin } from './CadastreMapSpin'
import { CadastreMapBasemapControl } from './controlls/CadastreMapBasemapControl/CadastreMapBasemapControl'
import { CadastreMapBuildingLayerControl } from './controlls/CadastreMapBuildingLayerControl/CadastreMapBuildingLayerControl'
import { CadastreMapOkresControl } from './controlls/CadastreMapOkresControl/CadastreMapOkresControl'
import { CadastreMapParcelLayerControl } from './controlls/CadastreMapParcelLayerControl/CadastreMapParcelLayerControl'
import { useBuildingPoints } from './hooks/useBuildingPoints'
import { useParcelPoints } from './hooks/useParcelPoints'
import { RuianLayer } from './types/RuianLayers'
import { addIcons } from './util/icons'
import { RuianQuery, RuianQueryInputGeometry } from './util/RuianQuery'

export interface CadastreMapProps {
  mapboxAccessToken: string

  showCadastre?: boolean
  showDebt?: boolean
  showInsolvency?: boolean
  showAuctions?: boolean

  longitude?: number
  latitude?: number
  zoom?: number

  onFeaturesSelection?: (
    features: Feature<
      Geometry,
      { objectid: string; layerId: string; layerName: string }
    >[]
  ) => void
}

export const CadastreMap: FunctionComponent<CadastreMapProps> = props => {
  const mapRef = useRef<MapRef>(null)
  const mapRefCallback = useCallback((map: MapRef | null) => {
    if (map === null) return
    // @ts-expect-error This is soonest we can get the map object
    mapRef.current = map
    map.addImage('pulsing-dot', CadastreMapHighlightIcon, { pixelRatio: 2 })
    addIcons(map, 24, [
      { name: 'default-budova', path: 'fluent/building-32-filled' },
      { name: 'default-parcela', path: 'mdi/land-plots-marker' },
      { name: 'default-jednotka', path: 'bi/building-fill-add' },
      { name: 'garaz', path: 'game-icons/home-garage' },
      { name: 'chata', path: 'fa6-solid/house-flood-water' },
      { name: 'zahrada', path: 'material-symbols/yard' },
      { name: 'pole', path: 'mdi/tractor' },
      { name: 'louka', path: 'carbon/soil-moisture-field' },
      { name: 'lesy', path: 'ic/round-forest' },
      { name: 'rybnik', path: 'ic/sharp-water' },
      { name: 'sady', path: 'uil/trees' },
      { name: 'notdef', path: 'fa-solid/question' },
    ])
  }, [])

  const [parcelsEnabled, setParcelsEnabled] = useState(false)
  const [parcelsClustering, setParcelsClustering] = useState(false)
  const [parcelsWhere, setParcelsWhere] = useState<string>('1=0')

  const [buildingsEnabled, setBuildingsEnabled] = useState(false)
  const [buildingsClustering, setBuildingsClustering] = useState(false)
  const [buildingsWhere, setbuildingsWhere] = useState<string>('1=0')

  const [geometryFilter, setGeometryFilter] =
    useState<RuianQueryInputGeometry | null>(null)

  const [radonEnabled, setRadonEnabled] = useState(false)

  const [baseMapRaster, setBaseMapRaster] = useState<string>('')

  const [loadingParacelPoints, parcelPoints] = useParcelPoints(
    parcelsWhere,
    !!parcelsEnabled,
    geometryFilter
  )
  const [loadingBuildingPoints, buildingPoints] = useBuildingPoints(
    buildingsWhere,
    !!buildingsEnabled,
    geometryFilter
  )

  const [highlightedFeatures, setHighlightedFeatures] = useState(
    turf.featureCollection([])
  )

  const { onFeaturesSelection } = props
  // TODO: Refactor into smaller functions, user either clicked on:
  // - interactive layer (points, polygons, etc.)
  // - cluster point
  // - map and are figuring out what was clicked using arcgis identify method
  const onClick = useCallback<NonNullable<MapProps['onClick']>>(
    async event => {
      const { current: map } = mapRef
      if (!map) return

      // Interactive layer was clicked (icons, etc.). When layer is marked as interactive we get features on event
      if (event?.features?.length) {
        // Due to how mapbox works there could be duplicate features, they have similar code in their official examples
        type ClusterPoint = Feature<
          Point & { cluster_id: number | undefined }
        > & {
          layer: LayerProps
          source: string
        }
        const features = event.features.reduce<ClusterPoint[]>(
          (acc, feature) => {
            if (acc.some(f => f.id === feature.id)) return acc
            return [...acc, feature as unknown as ClusterPoint]
          },
          [] as ClusterPoint[]
        )
        if (!features.length) return

        const [clickedFeature] = features

        // Cluster was clicked
        if (clickedFeature.properties?.cluster_id) {
          const clusterId = clickedFeature.properties.cluster_id
          const sourceName = clickedFeature.source
          const source = map.getSource<GeoJSONSource>(sourceName)
          if (!source) return
          const clusterPoint = clickedFeature.geometry.coordinates as [
            number,
            number
          ]
          source.getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (error || !zoom) return
            map.easeTo({ center: clusterPoint, zoom })
          })
          return
        } else {
          // Something else which is interactive was clicked, so we kinda zoom to it
          map.easeTo({
            center: clickedFeature.geometry.coordinates as [number, number],
            zoom: 17,
          })
          return
        }
      }

      // Map was clicked
      const point = event.lngLat.toArray() as [number, number]

      if (highlightedFeatures.features.length > 0) {
        setHighlightedFeatures(turf.featureCollection([]))
        return
      }

      if (map.getZoom() < 16.5) return

      const bounds = map.getBounds()
      if (!bounds) return
      const [[west, south], [east, north]] = bounds.toArray()
      const { width, height } = map.getCanvas()
      const identifiedFeatures = await RuianQuery.identify(
        [RuianLayer.STAVEBNI_OBJEKT, RuianLayer.PARCELA],
        [west, south, east, north],
        point,
        [width, height]
      )
      setHighlightedFeatures(identifiedFeatures)
      onFeaturesSelection?.(
        identifiedFeatures.features as Feature<
          Geometry,
          {
            objectid: string
            layerId: string
            layerName: string
          }
        >[]
      )
    },
    [highlightedFeatures, onFeaturesSelection]
  )

  const initialViewState = useConst({
    longitude: props?.longitude ?? 15.440327473659693,
    latitude: props?.latitude ?? 49.82376171768291,
    zoom: props?.zoom ?? 7.36778985715334,
    bearing: 0,
    pitch: 0,
  })

  useEffect(() => {
    if (!mapRef.current) return
    if (!props.longitude || !props.latitude) return
    mapRef.current?.flyTo({
      center: [props.longitude, props.latitude],
      zoom: props.zoom,
    })
  }, [props.longitude, props.latitude, props.zoom])

  const cssStyle = useConst<CSSProperties>({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  })

  const interactiveLayerIds = useConst([
    'insolvency-circle',
    'debt-circle',
    'auctions-cluster-circle',
    'auctions-non-clustered-point',
  ])

  return (
    <Map
      id="cadastreMap"
      initialViewState={initialViewState}
      style={cssStyle}
      pitchWithRotate={false}
      maxZoom={21}
      minZoom={7.5}
      touchZoomRotate={false}
      dragRotate={false}
      mapStyle="mapbox://styles/kolpav/clyopvz7f001w01ph85m10qns"
      mapboxAccessToken={props.mapboxAccessToken}
      onClick={onClick}
      ref={mapRefCallback}
      refreshExpiredTiles={false}
      cursor="pointer"
      interactiveLayerIds={interactiveLayerIds}
    >
      <Source id="parcel-points" data={parcelPoints} type="geojson" />
      <Source
        id="parcel-points-clustered"
        data={parcelPoints}
        type="geojson"
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      />
      <Source id="building-points" data={buildingPoints} type="geojson" />
      <Source
        id="building-points-clustered"
        data={buildingPoints}
        type="geojson"
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      />

      <CadastreMapRuianArcGisSource
        id="parcel-lines-unfiltered"
        ruianLayerId={RuianLayer.PARCELA}
        useStaticZoomLevel={false}
        minZoom={15}
      />
      <CadastreMapRuianArcGisSource
        id="building-lines-unfiltered"
        ruianLayerId={RuianLayer.STAVEBNI_OBJEKT}
        useStaticZoomLevel={false}
        minZoom={15}
      />

      <CadastreMapRuianArcGisSource
        id="parcel-fill-filtered"
        ruianLayerId={RuianLayer.PARCELA}
        where={parcelsEnabled ? parcelsWhere : '1=0'}
        useStaticZoomLevel={false}
        minZoom={15}
      />
      <CadastreMapRuianArcGisSource
        id="building-fill-filtered"
        ruianLayerId={RuianLayer.STAVEBNI_OBJEKT}
        where={buildingsEnabled ? buildingsWhere : '1=0'}
        useStaticZoomLevel={false}
        minZoom={15}
      />

      <Source
        id="selected-features"
        type="geojson"
        data={highlightedFeatures}
      />

      {baseMapRaster && (
        <Source
          id="basemap-raster"
          type="raster"
          tiles={[baseMapRaster]}
          tileSize={256}
          attribution='&copy; <a href="https://cuzk.cz">ČÚZK</a>'
          maxzoom={20}
        >
          <Layer
            id="basemap-raster"
            type="raster"
            source="basemap-raster"
            beforeId="road-path-trail"
            minzoom={0}
            maxzoom={22}
            paint={{
              'raster-brightness-min': 0,
              'raster-brightness-max': 0.8,
              'raster-contrast': 0.2,
              'raster-saturation': -0.2,
            }}
          />
        </Source>
      )}

      <CadastreMapBaseParcelRasterDisplay
        mode={baseMapRaster ? 'light' : 'dark'}
      />

      {radonEnabled && <CadastreMapRadonDisplay />}

      <CadastreMapAdministrationDisplay />

      <CadastreMapParcelLayers
        clustering={parcelsClustering}
        enabled={parcelsEnabled}
        pointsSource="parcel-points"
        clusterSource="parcel-points-clustered"
        filteredSource="parcel-fill-filtered"
        unfilteredSource="parcel-lines-unfiltered"
      />

      <CadastreMapBuildingLayers
        clustering={buildingsClustering}
        enabled={buildingsEnabled}
        pointsSource="building-points"
        clusterSource="building-points-clustered"
        filteredSource="building-fill-filtered"
        unfilteredSource="building-lines-unfiltered"
      />
      <CadastreMapSelectedFeatureDisplay source="selected-features" />

      {props.showDebt && (
        <CadastreMapDebtDisplay
          setHighlightedFeatures={setHighlightedFeatures}
        />
      )}

      {props.showInsolvency && (
        <CadastreMapInsolvencyDisplay
          setHighlightedFeatures={setHighlightedFeatures}
        />
      )}

      {props.showAuctions && (
        <CadastreMapAuctionDisplay initialViewState={initialViewState} />
      )}

      {props.showCadastre && (
        <>
          <CadastreMapOkresControl
            value={geometryFilter?.where.replace('objectid=', '') ?? undefined}
            onChange={okresObjectId => {
              if (!okresObjectId) {
                setGeometryFilter(null)
                return
              }
              setGeometryFilter({
                layerId: RuianLayer.OKRES,
                where: `objectid=${okresObjectId}`,
              })
            }}
          />
          <CadastreMapParcelLayerControl
            initialViewState={initialViewState}
            geometryFilter={geometryFilter}
            onWhereChange={where => {
              setParcelsWhere(where)
              setParcelsEnabled(true)
            }}
            enabled={parcelsEnabled}
            onEnabledChange={setParcelsEnabled}
            cluster={parcelsClustering}
            onClusterChange={setParcelsClustering}
            loading={loadingParacelPoints}
          />
          <CadastreMapBuildingLayerControl
            initialViewState={initialViewState}
            geometryFilter={geometryFilter}
            onWhereChange={where => {
              setbuildingsWhere(where)
              setBuildingsEnabled(true)
            }}
            enabled={buildingsEnabled}
            onEnabledChange={setBuildingsEnabled}
            cluster={buildingsClustering}
            onClusterChange={setBuildingsClustering}
            loading={loadingBuildingPoints}
          />
        </>
      )}

      <CadastreMapBasemapControl
        position="bottom-right"
        onRasterTileSource={source => {
          setBaseMapRaster(baseMapRaster === source ? '' : source)
        }}
        radonEnabled={radonEnabled}
        onRadonEnabledChange={enabled => setRadonEnabled(enabled)}
      />

      {(loadingBuildingPoints || loadingParacelPoints) && <CadastreMapSpin />}

      <CadastreMapDetailDrawer
        open={!!highlightedFeatures.features.length}
        onClose={() => setHighlightedFeatures(turf.featureCollection([]))}
        features={highlightedFeatures.features}
      />

      <GeocoderControl position="top-right" />
      <FullscreenControl position="bottom-left" />
      <GeolocateControl position="bottom-left" />
    </Map>
  )
}
