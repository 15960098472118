import { FileSearchOutlined } from '@ant-design/icons'
import { Icon } from '@iconify/react'
import { message, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import Papa from 'papaparse'
import React, { FunctionComponent, useCallback } from 'react'

import { PostServiceRecipient } from '../../../common/postServiceTypes'
import { europeCountries } from '../../utils/countriesList'
import { useConst } from '../../utils/useConst'

const { Dragger } = Upload

export interface PostServiceImportRecipientFormUploadProps {
  setParsedData: (data: PostServiceRecipient[]) => void
  hiddenDropArea?: boolean
}

const recipientCsvMap: {
  [key: string]: { csvName: string; required: boolean }
} = {
  companyName: { csvName: 'Společnost', required: false },
  firstname: { csvName: 'Křestní jméno', required: true },
  lastname: { csvName: 'Příjmení', required: true },
  callName: { csvName: 'Oslovení', required: false },
  gender: { csvName: 'Pohlaví', required: false },
  street: { csvName: 'Název ulice', required: true },
  houseNumber: { csvName: 'Číslo popisné', required: true },
  orientationNumber: { csvName: 'Číslo orientační', required: false },
  zipCode: { csvName: 'PSČ', required: true },
  city: { csvName: 'Obec', required: true },
  country: { csvName: 'Kód státu', required: false },

  variable1: { csvName: 'Proměnná 1', required: false },
  variable2: { csvName: 'Proměnná 2', required: false },
  variable3: { csvName: 'Proměnná 3', required: false },
  variable4: { csvName: 'Proměnná 4', required: false },
  variable5: { csvName: 'Proměnná 5', required: false },
  variable6: { csvName: 'Proměnná 6', required: false },
  variable7: { csvName: 'Proměnná 7', required: false },
  variable8: { csvName: 'Proměnná 8', required: false },
  variable9: { csvName: 'Proměnná 9', required: false },
  variable10: { csvName: 'Proměnná 10', required: false },

  propertyType: { csvName: 'Typ nemovitosti', required: false },
  propertyName: { csvName: 'Název nemovitosti', required: false },
  propertyLv: { csvName: 'Číslo LV', required: false },
  propertyCadastralCode: { csvName: 'Kód kat. území', required: false },
  propertyCadastralName: { csvName: 'Název kat. území', required: false },
  propertyCity: { csvName: 'Obec nemovitosti', required: false },
  propertyAddress: { csvName: 'Adresa nemovitosti', required: false },
  propertyAreaNumber: { csvName: 'Číslo parcely', required: false },
}

export const PostServiceImportRecipientFormUploadFragment: FunctionComponent<PostServiceImportRecipientFormUploadProps> =
  props => {
    const handleData = useCallback(
      (file: PostServiceRecipient[]): PostServiceRecipient[] => {
        return file.map((item, index) => {
          if (
            item.country === 'CZ' &&
            item.zipCode &&
            !/^\d{5}$/.test(item.zipCode)
          ) {
            item.zipCode = false as unknown as string
          }

          const error: string[] = Object.entries(item).reduce(
            (acc, [key, value]) => {
              if (value === false && recipientCsvMap[key]) {
                acc.push(recipientCsvMap[key].csvName)
              }
              return acc
            },
            [] as string[]
          )

          return {
            ...item,
            id: `${index}`,
            error: error.length > 0 ? error : undefined,
          }
        })
      },
      []
    )

    const handleFile = useCallback(
      (file: RcFile) => {
        Papa.parse(file, {
          delimiter: ';',
          header: true,
          comments: '#',
          skipEmptyLines: true,
          transformHeader: (header: string) => {
            for (const [key, value] of Object.entries(recipientCsvMap)) {
              if (value.csvName === header) {
                return key
              }
            }
            return header
          },

          transform: (value: string, name: keyof typeof recipientCsvMap) => {
            if (recipientCsvMap[name]?.required && !value) {
              return false
            }
            if (name === 'gender' && !!value) {
              const gender = value.toLowerCase()
              if (gender === 'm' || gender === 'f') {
                return gender
              }
              if (gender === 'ž' || gender === 'žena' || gender === 'w') {
                return 'f'
              }
              if (gender === 'muž') {
                return 'm'
              }
              return false
            }

            if (name === 'country') {
              const country = value ? value.toUpperCase() : 'CZ'
              return europeCountries.find(item => item.iso === country)
                ? country
                : false
            }

            if (name === 'zipCode' && !!value) {
              return value.replace(/\s/g, '')
            }
            return value
          },
          complete: (result: { data: PostServiceRecipient[] }) => {
            const parsedData = handleData(result.data)
            props.setParsedData(parsedData)
          },
          error: () => {
            message.error(`Nepodařilo se zpracovat soubor`)
          },
        })
        return false
      },
      [handleData, props]
    )

    const iconRender = useConst(() => (
      <FileSearchOutlined className="text-primary w-8 h-8" />
    ))

    const handleReset = useCallback(() => {
      props.setParsedData([])
    }, [props])

    return (
      <Dragger
        listType="picture"
        multiple={false}
        beforeUpload={handleFile}
        accept=".csv"
        showUploadList={true}
        iconRender={iconRender}
        disabled={false}
        className={props.hiddenDropArea ? 'csv-drop-area-hidden' : ''}
        onRemove={handleReset}
      >
        <div className="flex justify-center p-4">
          <Icon
            className="w-10 h-10 text-primaryHover"
            icon="bi:filetype-csv"
          />
        </div>
        <p className="ant-upload-text px-4">
          Klikněte nebo přetáhněte CSV soubor do tohoto pole.
        </p>
      </Dragger>
    )
  }
