import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { ForclosureMonitoringRecord } from '../../../common/foreclosureMonitoringTypes'
import { TransformedPropertyLabelData } from '../../../common/propertyDetailTypes'
import { UserActivityLogsResult } from '../../../common/userActivityLogTypes'
import { PropertyTableNotes } from '../../utils/propertyDetailNoteHelpers'
import { ForeclosureMonitoringTilesItem } from '../ForeclosureMonitoringTilesItem/ForeclosureMonitoringTilesItem'

export interface ForeclosureMonitoringTilesProps {
  data?: ForclosureMonitoringRecord[]
  propertyType: PROPERTY_TYPES
  userActivityLogs?: UserActivityLogsResult
  propertyNotes?: PropertyTableNotes
  propertyLabels?: TransformedPropertyLabelData | null
}

export const ForeclosureMonitoringTiles: FunctionComponent<ForeclosureMonitoringTilesProps> =
  ({ propertyNotes, propertyType, data, userActivityLogs, propertyLabels }) => {
    return (
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-y-8 gap-x-6">
        {data?.map(item => (
          <ForeclosureMonitoringTilesItem
            key={item.id}
            propertyType={propertyType}
            userActivityLog={userActivityLogs?.[item.id]}
            propertyNote={propertyNotes?.[item.id]}
            propertyLabels={propertyLabels?.[item.id]}
            {...item}
          />
        ))}
      </div>
    )
  }
