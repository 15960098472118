import { EyeOutlined } from '@ant-design/icons'
import { Badge, Tag, Tooltip } from 'antd'
import React, { FunctionComponent, memo, useMemo } from 'react'

import { TransformedPropertyLabelDataItem } from '../../../common/propertyDetailTypes'
import { UserActivityLog } from '../../../common/userActivityLogTypes'
import { Day } from '../DateFormat/DateFormat'
import { PropertyTableItemLabel } from '../PropertyTableItemLabel/PropertyTableItemLabel'

export interface PropertyMonitoringTilesItemRibbonProps {
  alertDateTo?: string | null
  userActivityLog?: UserActivityLog | null
  propertyLabels?: TransformedPropertyLabelDataItem
  children: React.ReactNode
}

export const PropertyMonitoringTilesItemRibbon: FunctionComponent<PropertyMonitoringTilesItemRibbonProps> =
  memo(props => {
    const alertDateTo = useMemo(() => {
      if (!props.alertDateTo || !props.userActivityLog) return null
      return (
        new Date(props.alertDateTo) > new Date(props.userActivityLog.editedAt)
      )
    }, [props.alertDateTo, props.userActivityLog])

    const ribbonText = useMemo(
      () => (
        <div className="flex flex-wrap items-center">
          <Tooltip
            title={
              alertDateTo ? (
                <span>
                  Od vašeho posledního náhledu detailu (
                  <Day>{props.userActivityLog?.editedAt}</Day>) proběhla změna.
                </span>
              ) : (
                <span>
                  Váš poslední náhled detailu:{' '}
                  <Day>{props.userActivityLog?.editedAt}</Day>
                </span>
              )
            }
          >
            <Tag
              icon={<EyeOutlined />}
              color={alertDateTo ? 'warning' : 'default'}
              className="!border-0"
            >
              <Day>{props.userActivityLog?.editedAt}</Day>
            </Tag>
          </Tooltip>
          {props.propertyLabels?.propertyData?.propertyId && (
            <PropertyTableItemLabel
              editOnly
              propertyId={props.propertyLabels.propertyData.propertyId}
              propertyType={props.propertyLabels.propertyData.propertyType}
              propertyLabel={props.propertyLabels}
            />
          )}
        </div>
      ),
      [alertDateTo, props.propertyLabels, props.userActivityLog?.editedAt]
    )

    return (
      <Badge.Ribbon
        text={ribbonText}
        placement="end"
        className={
          props.userActivityLog ? '!h-auto !py-1 !shadow-sm' : 'hidden'
        }
        color={alertDateTo ? '#fffbe6' : 'white'}
      >
        {props.children}
      </Badge.Ribbon>
    )
  })
