import { Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/es/table/interface'
import { compareAsc } from 'date-fns'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { VlastnickePravo } from '../../../common/lvTypes'
import { RuianBuildingResponse } from '../../../common/ruianTypes'
import { useAppSelector } from '../../../redux/hooks'
import { stringSorter } from '../../utils/generalTableHelpers'
import { propertyDetailTableProps } from '../../utils/layoutConst'
import {
  createOwnershipRecord,
  ownershipHistoryClassName,
  OwnershipRightItem,
  shareRender,
  typeIconMap,
} from '../../utils/ownershipRightsTableHelpers'
import { usePostServicePropertyFromRedux } from '../../utils/usePostServicePropertyFromRedux'
import { useSubjectsInPostService } from '../../utils/useSubjectsInPostService'
import { CUZK_PERSON_TYPES } from '../CuzkEpoModalButton/CuzkEpoModalButton'
import { Day } from '../DateFormat/DateFormat'
import { DistanceTableCell } from '../DistanceTableCell/DistanceTableCell'
import { PropertyDetailOwnershipRightsTableCellAddress } from '../PropertyDetailOwnershipRightsTableCellAddress/PropertyDetailOwnershipRightsTableCellAddress'
import { PropertyDetailOwnershipRightsTableCellValidFrom } from '../PropertyDetailOwnershipRightsTableCellValidFrom/PropertyDetailOwnershipRightsTableCellValidFrom'
import { PropertyDetailOwnershipRightsTableSummary } from '../PropertyDetailOwnershipRightsTableSummary/PropertyDetailOwnershipRightsTableSummary'
import { SubjectTableCellPostService } from '../SubjectTableCellPostService/SubjectTableCellPostService'

interface PropertyDetailOwnershipRightsTableProps {
  rights: VlastnickePravo[]
  showHistory?: boolean
  setShowHistory?: (showHistory: boolean) => void
  isDrawer?: boolean
}

export const PropertyDetailOwnershipRightsTable: FunctionComponent<PropertyDetailOwnershipRightsTableProps> =
  ({ rights, showHistory, isDrawer }) => {
    const ruianDetail = useAppSelector(
      state => state.property.ruian?.data
    ) as RuianBuildingResponse

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRow, setSelectedRow] = useState<OwnershipRightItem[]>([])
    const [mergeColumns, setMergeColumns] = useState(true)

    const propertyData = usePostServicePropertyFromRedux()

    const dataSource = useMemo(
      () =>
        rights
          .flatMap(right => {
            const originalRecord = createOwnershipRecord(
              right,
              right.subjekt,
              right.sjmPartner,
              null
            )
            const partnerRecord = right.sjmPartner
              ? createOwnershipRecord(
                  right,
                  right.sjmPartner,
                  null,
                  right.subjekt
                )
              : null
            return partnerRecord
              ? [originalRecord, partnerRecord]
              : [originalRecord]
          })
          .filter(
            right =>
              right.share.numerator && (showHistory || right.validTo === null)
          ),
      [rights, showHistory]
    )

    const uniquePersonKeys = useMemo(
      () => dataSource.map(subject => subject.uniquePersonKey),
      [dataSource]
    )
    const {
      subjectPostService,
      refetch,
      loading: labelsLoading,
    } = useSubjectsInPostService(uniquePersonKeys)

    const postServiceRenderer = useCallback(
      (_: string, record: OwnershipRightItem) =>
        labelsLoading ? (
          <Spin size="small" />
        ) : (
          <SubjectTableCellPostService
            postServiceData={subjectPostService?.[record.uniquePersonKey]}
            addressId={record.addressId}
            subjectId={record.id}
            fullAddress={record.address}
            fullName={record.name}
            refetch={refetch}
            companyName={
              record.type === CUZK_PERSON_TYPES.OPO ? record.name : undefined
            }
            property={propertyData}
          />
        ),
      [labelsLoading, propertyData, refetch, subjectPostService]
    )

    const getRowSpan = useCallback(
      (record: OwnershipRightItem, index?: number) => {
        if (!mergeColumns) return {}
        if ((index !== 0 && record.sjmParent) || record.sjmPartner) {
          const match = selectedRow.some(
            selected => selected.id === record.sjmPartner?.id
          )
          return {
            rowSpan: record.sjmPartner ? 2 : 0,
            className: match ? '!bg-[#e1f0ee]' : '',
          }
        }
        return {}
      },
      [mergeColumns, selectedRow]
    )

    const getAddressRowSpan = useCallback(
      (record: OwnershipRightItem, index?: number) => {
        if (!mergeColumns) return {}
        if (record.addressId === record.sjmPartner?.adresniMistoKod) {
          const match = selectedRow.some(
            selected => selected.id === record.sjmPartner?.id
          )
          return {
            rowSpan: 2,
            className: match ? '!bg-[#e1f0ee]' : '',
          }
        }
        if (
          index !== 0 &&
          record.addressId === record.sjmParent?.adresniMistoKod
        ) {
          return { rowSpan: 0 }
        }
        return {}
      },
      [mergeColumns, selectedRow]
    )

    const handleOnChange = useCallback(
      (
        _: TablePaginationConfig,
        __: Record<string, FilterValue | null>,
        sort:
          | SorterResult<OwnershipRightItem>
          | SorterResult<OwnershipRightItem>[]
      ) => {
        sort = Array.isArray(sort) ? sort[0] : sort
        if (!sort.order) setMergeColumns(true)
        else setMergeColumns(false)
      },
      []
    )

    const columns: ColumnsType<OwnershipRightItem> = useMemo(
      () => [
        ...(!isDrawer
          ? ([
              {
                title: 'Typ',
                dataIndex: 'type',
                className: 'w-12 text-center',
                sorter: stringSorter('type'),
                onCell: getRowSpan,
                render: (_, record) => typeIconMap[record.type],
              },
            ] as ColumnsType<OwnershipRightItem>)
          : []),

        {
          title: 'Jméno / název',
          dataIndex: 'name',
          sorter: stringSorter('name'),
          render: (name, record) => (
            <a
              href={`https://data.regesta.cz/Vyhledavani/Subjekt/${record.id}`}
              title="Zobrazit detail subjektu"
            >
              {name}
            </a>
          ),
        },

        {
          dataIndex: 'addressId',
          className: 'text-right w-8',
          onCell: getAddressRowSpan,
          render: (addressId, record) => (
            <DistanceTableCell
              addressCode={addressId}
              referencePoint={ruianDetail?.definicniBod}
              referenceCodes={ruianDetail?.adresniMista}
              personType={record.type}
            />
          ),
        },
        {
          title: 'Adresa',
          dataIndex: 'address',
          onCell: getAddressRowSpan,
          sorter: stringSorter('address'),
          render: (address, record) => (
            <PropertyDetailOwnershipRightsTableCellAddress
              address={address}
              addressId={record.addressId}
            />
          ),
        },

        {
          title: 'Pošta',
          dataIndex: 'postService',
          className: 'w-24',
          render: postServiceRenderer,
        },
        {
          title: 'Podíl',
          dataIndex: 'share',
          className: 'w-20',
          onCell: getRowSpan,
          sorter: (a, b) => a.share.percentage - b.share.percentage,
          render: shareRender,
        },

        ...(!isDrawer
          ? ([
              {
                title: 'Platné od',
                dataIndex: 'validFrom',
                className: 'w-28 !text-right',
                onCell: getRowSpan,
                sorter: (a, b) =>
                  compareAsc(
                    new Date(a.validFrom || 0),
                    new Date(b.validFrom || 0)
                  ),
                render: validDateFrom => (
                  <PropertyDetailOwnershipRightsTableCellValidFrom
                    validDateFrom={validDateFrom}
                  />
                ),
              },
            ] as ColumnsType<OwnershipRightItem>)
          : []),

        ...(showHistory
          ? [
              {
                title: 'Platné do',
                dataIndex: 'validTo',
                className: 'w-28 !text-right',
                onCell: getRowSpan,
                sorter: (a: OwnershipRightItem, b: OwnershipRightItem) =>
                  compareAsc(
                    new Date(a.validTo || 0),
                    new Date(b.validTo || 0)
                  ),
                render: (validTo?: string | null) =>
                  validTo ? <Day>{validTo}</Day> : null,
              },
            ]
          : []),
      ],
      [
        getAddressRowSpan,
        getRowSpan,
        isDrawer,
        postServiceRenderer,
        ruianDetail?.adresniMista,
        ruianDetail?.definicniBod,
        showHistory,
      ]
    )
    const rowSelection = useMemo<TableRowSelection<OwnershipRightItem>>(
      () => ({
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
          setSelectedRowKeys(newSelectedRowKeys)
          setSelectedRow(selectedRows)
        },
      }),
      [selectedRowKeys]
    )

    const summary = useCallback(
      () => (
        <PropertyDetailOwnershipRightsTableSummary
          rights={rights}
          dataSourceLength={dataSource.length}
          isDrawer={isDrawer}
        />
      ),
      [dataSource.length, isDrawer, rights]
    )

    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        summary={summary}
        rowClassName={ownershipHistoryClassName}
        rowSelection={isDrawer ? undefined : rowSelection}
        onChange={handleOnChange}
        {...propertyDetailTableProps}
      />
    )
  }
