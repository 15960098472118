import { Button, message, Popconfirm } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { FunctionComponent, useCallback } from 'react'

import { useDeletePostServiceRecipientListMutation } from '../../../graphql/generated'

export interface PostServiceDeleteRecipientListModalButtonProps {
  listId: string
  listName?: string
  reexecuteQuery?: () => void
  buttonProps?: ButtonProps
  buttonText?: string
  title?: string
}

const okButtonProps = { danger: true }
const overlayInnerStyle = { width: '250px' }

export const PostServiceDeleteRecipientListModalButton: FunctionComponent<PostServiceDeleteRecipientListModalButtonProps> =
  props => {
    const [, deleteRecipientList] = useDeletePostServiceRecipientListMutation()

    const removeRecipientList = useCallback(async () => {
      const result = await deleteRecipientList({
        id: props.listId,
        deletedAt: new Date(),
      })
      if (result.error) {
        message.error('Seznam adresátů se nepodařilo odebrat.')
      } else {
        message.success('Seznam adresátů byl odebrán.')
      }
      props.reexecuteQuery?.()
    }, [deleteRecipientList, props])

    return (
      <Popconfirm
        title={
          props.title ||
          `Opravdu chcete smazat seznam adresátů ${props.listName || ''}?`
        }
        onConfirm={removeRecipientList}
        okText="Smazat"
        cancelText="Zrušit"
        okButtonProps={okButtonProps}
        overlayInnerStyle={overlayInnerStyle}
      >
        <Button
          size="small"
          className="w-full"
          danger
          type="link"
          {...props.buttonProps}
        >
          {props.buttonText || 'Smazat seznam'}
        </Button>
      </Popconfirm>
    )
  }
