import React, { FunctionComponent } from 'react'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { InsolvencyMonitoringRecord } from '../../../common/insolvencyMonitoringTypes'
import { TransformedPropertyLabelData } from '../../../common/propertyDetailTypes'
import { UserActivityLogsResult } from '../../../common/userActivityLogTypes'
import { PropertyTableNotes } from '../../utils/propertyDetailNoteHelpers'
import { InsolvencyMonitoringTilesItem } from '../InsolvencyMonitoringTilesItem/InsolvencyMonitoringTilesItem'

export interface InsolvencyMonitoringTilesProps {
  data?: InsolvencyMonitoringRecord[]
  propertyType: PROPERTY_TYPES
  propertiesActivity?: UserActivityLogsResult
  propertyNotes?: PropertyTableNotes
  propertyLabels?: TransformedPropertyLabelData | null
}

export const InsolvencyMonitoringTiles: FunctionComponent<InsolvencyMonitoringTilesProps> =
  ({
    propertyNotes,
    propertyType,
    data,
    propertiesActivity,
    propertyLabels,
  }) => {
    return (
      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 gap-y-8 gap-x-6">
        {data?.map((item, index) => (
          <InsolvencyMonitoringTilesItem
            key={item.id + index}
            propertyType={propertyType}
            userActivityLog={propertiesActivity?.[item.id]}
            propertyNote={propertyNotes?.[item.id]}
            propertyLabels={propertyLabels?.[item.id]}
            {...item}
          />
        ))}
      </div>
    )
  }
