import { Breakpoint, Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { PROPERTY_TYPES } from '../../common/drmTypes'
import {
  PostServiceRecipient,
  SentRecipient,
} from '../../common/postServiceTypes'
import { AddressLink } from '../components/AddressLink/AddressLink'
import { lvDetailPath } from './paths'

export const showVariableColumns = (
  data: Partial<SentRecipient>[] | Partial<PostServiceRecipient>[] | undefined
) =>
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => {
    const variableKey = `variable${num}` as keyof SentRecipient
    return {
      title: `Prom #${num}`,
      dataIndex: variableKey,
      responsive:
        (data as Partial<SentRecipient>[])?.find(item => item[variableKey]) ||
        num === 1
          ? undefined
          : ['' as Breakpoint],
      sorter: (a: SentRecipient, b: SentRecipient) =>
        a[variableKey] && b[variableKey]
          ? (a[variableKey] as string).localeCompare(b[variableKey] as string)
          : -1,
    }
  })

export const postServiceAddressRenderer = (
  _: string,
  record: SentRecipient | PostServiceRecipient
) => {
  if (!record) return null

  const streetParts = [
    record.street,
    record.houseNumber,
    record.orientationNumber ? '/' + record.orientationNumber : '',
  ]
  const street = streetParts.join(' ').trim()
  const fullAddress =
    street + ' ' + record?.city + ' ' + record?.zipCode + ' ' + record?.country

  return record.addressId ? (
    <AddressLink address={fullAddress} addressId={record.addressId} />
  ) : (
    fullAddress
  )
}

export const postServicePropertyRenderer = (
  _: string,
  record: SentRecipient | PostServiceRecipient
) => {
  const empty = <span className="text-gray-400">- nevyplněno -</span>
  const tooltipContent = (
    <div className="grid grid-cols-[70px,auto] w-96 gap-x-8 gap-y-1">
      <div>Název</div>
      <div>{record?.propertyName || empty}</div>
      <div>Typ</div>
      <div>{record?.propertyType || empty}</div>
      <div>Kat. území</div>
      <div>{record?.propertyCadastralName || empty}</div>
      <div>Číslo LV</div>
      <div>{record?.propertyLv || empty}</div>
      <div>Obec</div>
      <div>{record?.propertyCity || empty}</div>
      {(record?.propertyType === PROPERTY_TYPES.BUILDING ||
        record?.propertyType === PROPERTY_TYPES.UNIT) && (
        <>
          <div>Adresa</div>
          <div>{record?.propertyAddress || empty}</div>
        </>
      )}
      {record?.propertyType === PROPERTY_TYPES.AREA && (
        <>
          <div>Parcelní č.</div>
          <div>{record?.propertyAreaNumber || empty}</div>
        </>
      )}
    </div>
  )

  const linkPath =
    record?.propertyCadastralCode && record?.propertyLv
      ? lvDetailPath(record.propertyCadastralCode, record.propertyLv)
      : ''

  const name = () => {
    if (linkPath)
      return (
        record?.propertyName ||
        `LV ${record?.propertyLv} - ${record?.propertyCadastralName}`
      )

    return (
      <Tooltip
        title={tooltipContent}
        overlayClassName="py-4 min-w-min !max-w-none"
      >
        {record?.propertyName ||
          record?.propertyAddress ||
          record?.propertyType}
      </Tooltip>
    )
  }

  if (!linkPath) return name()
  return (
    <Tooltip
      title={tooltipContent}
      overlayClassName="py-4 min-w-min !max-w-none"
    >
      <Link
        target="_blank"
        title="Otevřít náhled LV v novém okně"
        to={linkPath}
      >
        {name()}
      </Link>
    </Tooltip>
  )
}
