import { Spin, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { PROPERTY_TYPES } from '../../../common/drmTypes'
import { InsolvencyMonitoringRecord } from '../../../common/insolvencyMonitoringTypes'
import { TransformedPropertyLabelData } from '../../../common/propertyDetailTypes'
import { UserActivityLogsResult } from '../../../common/userActivityLogTypes'
import {
  nestedStringSorter,
  numberSorter,
} from '../../utils/generalTableHelpers'
import { PropertyTableNotes } from '../../utils/propertyDetailNoteHelpers'
import {
  insolvencyUrl,
  restrictionColumn,
} from '../../utils/propertyMonitoringHelpers'
import { isCompanyName } from '../../utils/subjectHelpers'
import { seenRowClassName } from '../../utils/userActivityLogHelpers'
import { useSetPropertyDrawer } from '../../utils/useSetPropertyDrawer'
import {
  watchdogAreaColumn,
  watchdogBuildingColumn,
  watchdogUnitColumn,
} from '../../utils/watchdogHelpers'
import { Day } from '../DateFormat/DateFormat'
import { Money } from '../Money/Money'
import { PropertyNoteModalButton } from '../PropertyNoteModalButton/PropertyNoteModalButton'
import { PropertyTableItemLabel } from '../PropertyTableItemLabel/PropertyTableItemLabel'
import { PropertyTimelineOwnershipTitleIcon } from '../PropertyTimelineOwnershipTitleIcon/PropertyTimelineOwnershipTitleIcon'
import { SubjectLink } from '../SubjectLink/SubjectLink'
import { UserActivityLogBadge } from '../UserActivityLogBadge/UserActivityLogBadge'

export interface InsolvencyMonitoringTableProps {
  data?: InsolvencyMonitoringRecord[] | null
  propertyType: PROPERTY_TYPES
  userActivityLogs?: UserActivityLogsResult
  propertyNotes?: PropertyTableNotes
  propertyLabels?: 'loading' | TransformedPropertyLabelData | null
}

const tableId = 'insolvency-monitoring'
const scrollX = { x: 1400 }
const rowKey = () => uuidv4()

export const InsolvencyMonitoringTable: FunctionComponent<InsolvencyMonitoringTableProps> =
  ({
    data,
    userActivityLogs: propertiesActivity,
    propertyNotes,
    propertyType,
    propertyLabels,
  }) => {
    const setDrawer = useSetPropertyDrawer()

    const columns = useMemo<ColumnsType<InsolvencyMonitoringRecord>>(
      () => [
        {
          title: 'Poslední změna stavu',
          dataIndex: ['insVec', 'posledniZmenaStavu'],
          width: 115,
          sorter: nestedStringSorter('insVec', 'posledniZmenaStavu'),
          render: (posledniZmenaStavu: string) =>
            posledniZmenaStavu && <Day>{posledniZmenaStavu}</Day>,
        },
        {
          title: 'Katastrální pracoviště',
          dataIndex: ['pracoviste', 'nazev'],
          ellipsis: true,
          sorter: nestedStringSorter('pracoviste', 'nazev'),
        },
        {
          title: 'Štítky',
          dataIndex: 'id',
          key: 'labels',
          ellipsis: true,
          render: (propertyId: string) =>
            propertyLabels === 'loading' ? (
              <Spin size="small" />
            ) : (
              <PropertyTableItemLabel
                propertyId={propertyId}
                propertyType={propertyType}
                propertyLabel={propertyLabels?.[propertyId]}
                editOnly
              />
            ),
        },
        {
          title: 'Způsob využití',
          dataIndex: ['zpusobVyuziti', 'nazev'],
          sorter: nestedStringSorter('zpusobVyuziti', 'nazev'),
          render: (zpusobVyuziti, record) => (
            <a
              onClick={() => setDrawer(record.id, propertyType)}
              title="Otevřít detail nemovitosti v novém okně"
            >
              {zpusobVyuziti || '- neuvedeno -'}
            </a>
          ),
        },

        ...(propertyType === PROPERTY_TYPES.AREA
          ? ([
              {
                title: 'Druh pozemku',
                dataIndex: ['druhPozemku', 'nazev'],
                sorter: nestedStringSorter('druhPozemku', 'nazev'),
                render: (druhPozemku, record) => (
                  <a
                    onClick={() => setDrawer(record.id, propertyType)}
                    title="Otevřít detail nemovitosti v novém okně"
                  >
                    {druhPozemku || '- Druh nezjištěn -'}
                  </a>
                ),
              },
            ] as ColumnsType<InsolvencyMonitoringRecord>)
          : []),

        {
          title: 'Spisová zn.',
          dataIndex: ['insVec', 'spisovaZnacka'],
          width: 130,
          sorter: nestedStringSorter('insVec', 'spisovaZnacka'),
          render: (spisovaZnacka: string, record) => (
            <a
              href={insolvencyUrl(record.insVec?.spisovaZnacka)}
              target="_blank"
              rel="noreferrer"
            >
              {spisovaZnacka}
            </a>
          ),
        },
        {
          title: 'Stav ins.',
          dataIndex: ['insVec', 'stavRizeni', 'nazev'],
          truncate: true,
          sorter: (a, b) =>
            (a.insVec?.stavRizeni?.nazev || '').localeCompare(
              b.insVec?.stavRizeni?.nazev || ''
            ),
          render: stavRizeni => stavRizeni,
        },
        {
          title: 'Typ',
          width: 45,
          dataIndex: ['subjekt', 'nazev'],
          key: 'subjectType',
          className: '!text-right',
          sorter: (a, b) =>
            (isCompanyName(a.subjekt?.nazev) ? 1 : 0) -
            (isCompanyName(b.subjekt?.nazev) ? 1 : 0),
          render: subjectName => (
            <PropertyTimelineOwnershipTitleIcon subjectName={subjectName} />
          ),
        },
        {
          title: 'Dlužník',
          truncate: true,
          dataIndex: ['subjekt', 'nazev'],
          sorter: nestedStringSorter('subjekt', 'nazev'),
          render: (subjectName, record) => (
            <SubjectLink
              newTab
              subjectId={record.subjekt?.id}
              className="block truncate"
            >
              {subjectName}
            </SubjectLink>
          ),
        },

        restrictionColumn.zastavySmluvni,
        restrictionColumn.zastavyNedobrovolne,

        {
          title: 'Podíl',
          dataIndex: 'podil',
          width: 85,
          className: '!text-right',
          sorter: numberSorter('podil'),
          render: podil => (
            <Money amount={podil * 100} decimalNumbers={2} symbol="%" />
          ),
        },
        {
          title: 'Poznámka',
          dataIndex: 'id',
          key: 'note',
          truncate: true,
          render: id => (
            <PropertyNoteModalButton
              className="block truncate"
              note={propertyNotes?.[id]}
            />
          ),
        },
        {
          title: 'Info',
          dataIndex: 'id',
          key: 'info',
          width: 110,
          render: (id, record) => (
            <UserActivityLogBadge
              userActivityLog={propertiesActivity?.[id]}
              alertDateTo={record.insVec?.posledniZmenaStavu}
            />
          ),
        },
        propertyType === PROPERTY_TYPES.AREA
          ? watchdogAreaColumn
          : propertyType === PROPERTY_TYPES.BUILDING
          ? watchdogBuildingColumn
          : watchdogUnitColumn,
      ],
      [
        propertiesActivity,
        propertyNotes,
        propertyType,
        propertyLabels,
        setDrawer,
      ]
    )

    const rowClassName = useCallback(
      (record: InsolvencyMonitoringRecord) =>
        seenRowClassName(
          propertiesActivity?.[record.id]?.editedAt,
          record.insVec?.posledniZmenaStavu
        ),
      [propertiesActivity]
    )

    return (
      <Table
        rowKey={rowKey}
        rowClassName={rowClassName}
        size="small"
        id={tableId}
        columns={columns}
        dataSource={data || []}
        pagination={false}
        bordered
        scroll={scrollX}
      />
    )
  }
