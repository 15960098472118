/**
 * Enumeration representing various geographic and administrative units.
 */
export enum RuianLayer {
  PARCELA_DEFINICNI_BOD = '0',
  ADRESNI_MISTO = '1',
  STAVEBNIHO_OBJEKTU_DEFINICNI_BOD = '2',
  STAVEBNI_OBJEKT = '3',
  ULICE = '4',
  PARCELA = '5',
  ZAKLADNI_SIDEJNI_JEDNOTKA = '6',
  KATASTRALNI_UZEMI = '7',
  MOMC = '8',
  OBVOD_V_HLAVNIM_MESTE_PRAZE = '9',
  SPRAVNI_OBVOD_V_HLAVNIM_MESTE_PRAZE = '10',
  CAST_OBCE = '11',
  OBEC = '12',
  OBEC_S_POVERENYM_OBECNIM_URADEM = '13',
  OBEC_S_ROZSIRENOU_PUSOBNOSTI = '14',
  OKRES = '15',
  VYSSI_UZEMNE_SAMOSPRAVNY_CELEK = '17',
  REGION_SOUDRZNOSTI = '18',
  STAT = '19',
  VOLEBNI_OKRSEK = '20',
  CHRANENE_LOZISKOVE_UZEMI = '21',
  DOBYVACI_PROSTOR = '22',
  OCHRANNE_PASMO_ZNACKY_BODU_ZAKLADNIHO_BODOVEHO_POLE = '24',
  ZNACKA_BODU_ZAKLADNIHO_BODOVEHO_POLE = '25',
  BONITOVANA_PUDNE_EKOLOGICKA_JEDNOTKA_DEFINICNI_BOD = '26',
  BONITOVANA_PUDNE_EKOLOGICKA_JEDNOTKA = '27',
}
