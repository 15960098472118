import { MapRef } from 'react-map-gl'

type Image = Parameters<MapRef['addImage']>[1] & {
  data: Uint8ClampedArray
  map: Omit<MapRef, 'getMap'> | null
  context: CanvasRenderingContext2D | null
}

const size = 48

export const CadastreMapHighlightIcon: Image = {
  width: size,
  height: size,
  data: new Uint8ClampedArray(size * size * 4),
  map: null,
  context: null,
  onAdd(map) {
    this.map = map
    const canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height
    this.context = canvas.getContext('2d')
    if (!this.context) throw new Error('Failed to get 2D context')
  },
  render() {
    if (!this.context || !this.map) return true
    const duration = 1000
    const t = (performance.now() % duration) / duration

    const radius = (size / 2) * 0.3
    const outerRadius = (size / 2) * 0.7 * t + radius

    this.context.clearRect(0, 0, this.width, this.height)

    this.context.beginPath()
    this.context.arc(
      this.width / 2,
      this.height / 2,
      outerRadius,
      0,
      Math.PI * 2
    )
    this.context.fillStyle = `rgba(255, 0, 0, ${1 - t})`
    this.context.fill()

    this.context.beginPath()
    this.context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)

    this.context.shadowColor = 'rgba(0, 0, 0, 0.3)'
    this.context.shadowBlur = 4
    this.context.shadowOffsetX = 0
    this.context.shadowOffsetY = 2

    this.context.fillStyle = 'rgba(255, 0, 0, 1)'
    this.context.strokeStyle = 'rgba(0, 0, 0, 0.5)'
    this.context.lineWidth = 2 + 4 * (1 - t)
    this.context.fill()
    this.context.stroke()

    this.context.shadowColor = 'transparent'

    this.data = this.context.getImageData(0, 0, this.width, this.height).data

    this.map.triggerRepaint()

    return true
  },
}
