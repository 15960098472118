import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, message } from 'antd'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { PostServiceAddRecipientFormValues } from '../../../common/postServiceTypes'
import {
  useCreatePostserviceRecipientMutation,
  useUpdatePostserviceRecipientMutation,
} from '../../../graphql/generated'
import { useAppSelector } from '../../../redux/hooks'
import { postServiceFormHelp as _help } from '../../utils/cuzkMonitorRcFormHelp'
import { itemRequired } from '../../utils/formHelpers'
import { getUniquePersonKey } from '../../utils/getUniquePersonKey'
import {
  formItemLayout,
  fullItemLayoutProps,
  validateTrigger,
} from '../../utils/layoutConst'
import { AresSearchBar } from '../AresSearchBar/AresSearchBar'
import { PostServiceAddRecipientFormCountryFragment as _CountrySelect } from '../PostServiceAddRecipientFormCountryFragment/PostServiceAddRecipientFormCountryFragment'
import { PostServiceAddRecipientFormPersonFragment as _PersonFragment } from '../PostServiceAddRecipientFormPersonFragment/PostServiceAddRecipientFormPersonFragment'
import { PostServiceAddRecipientFormPropertyFragment as _PropertyFragment } from '../PostServiceAddRecipientFormPropertyFragment/PostServiceAddRecipientFormPropertyFragment'
import { PostServiceAddRecipientFormSearchAddressFragment as _AddressSearch } from '../PostServiceAddRecipientFormSearchAddressFragment/PostServiceAddRecipientFormSearchAddressFragment'
import { PostServiceAddRecipientFormStreetFragment as _StreetFragment } from '../PostServiceAddRecipientFormStreetFragment/PostServiceAddRecipientFormStreetFragment'
import { PostServiceAddRecipientFormVariablesFragment as _VariablesFragment } from '../PostServiceAddRecipientFormVariablesFragment/PostServiceAddRecipientFormVariablesFragment'

export interface PostServiceAddRecipientFormProps {
  listId: string
  listName: string
  hideModal: () => void
  handleHelpToggle: () => void
  reexecuteQuery?: () => void
  showHelp: boolean
  hideAddAnother?: boolean
  hideAresSearch?: boolean
  hideAddressSearch?: boolean
  initialValues?: Partial<PostServiceAddRecipientFormValues & { id: string }>
}

export const PostServiceAddRecipientForm: FunctionComponent<PostServiceAddRecipientFormProps> =
  props => {
    const [form] = Form.useForm()
    const tenantId = useAppSelector(state => state.myProfile.tenantId)
    const userId = useAppSelector(state => state.myProfile.userId)

    const [loading, setLoading] = useState(false)
    const [keepModal, setKeepModal] = useState(false)

    const [, createPostserviceRecipient] =
      useCreatePostserviceRecipientMutation()
    const [, editPostserviceRecipient] = useUpdatePostserviceRecipientMutation()

    const onFinish = useCallback(
      async (formValues: PostServiceAddRecipientFormValues) => {
        setLoading(true)
        const baseValues = {
          ...formValues,
          houseNumber: formValues.street.houseNumber,
          street: formValues.street.streetName,
          orientationNumber: formValues.street.orientationNumber,
          gender: formValues.gender === 0 ? undefined : formValues.gender,
        }

        if (props.initialValues?.id) {
          const result = await editPostserviceRecipient({
            id: props.initialValues.id,
            ...baseValues,
          })
          if (result.error) {
            message.error('Adresáta se nepodařilo upravit.')
          } else {
            message.success('Adresát byl upraven.')
            form.resetFields()
          }
        } else {
          const uniquePersonKey =
            baseValues.uniquePersonKey ||
            getUniquePersonKey(
              baseValues.firstname + ' ' + baseValues.lastname,
              baseValues?.addressId,
              `${baseValues.street} ${baseValues.houseNumber} ${baseValues.orientationNumber}`
            )
          const result = await createPostserviceRecipient({
            ...baseValues,
            accountId: tenantId,
            accountUserId: userId,
            postserviceListId: props.listId,
            uniquePersonKey,
          })

          if (result.error) {
            message.error('Nepodařilo se vytvořit adresáta.')
          } else {
            form.resetFields()
            message.success('Nový adresát zařazen do seznamu.')
          }
        }
        setLoading(false)
        props.reexecuteQuery?.()
        !keepModal && props.hideModal()
      },
      [
        createPostserviceRecipient,
        editPostserviceRecipient,
        form,
        keepModal,
        props,
        tenantId,
        userId,
      ]
    )

    const submit = useCallback(() => {
      setKeepModal(false)
      form.submit()
    }, [form])

    const submitAndAddAnother = useCallback(() => {
      setKeepModal(true)
      form.submit()
    }, [form])

    return (
      <Form<PostServiceAddRecipientFormValues>
        {...formItemLayout}
        layout="horizontal"
        form={form}
        initialValues={props.initialValues}
        validateTrigger={validateTrigger}
        onFinish={onFinish}
        className="!mt-4"
      >
        {!props.hideAresSearch && (
          <Form.Item
            className="w-full"
            label={<span className="font-semibold">Vyhledat subjekt</span>}
            name="aresSearch"
            help={props.showHelp ? _help.aresSearch : undefined}
          >
            <AresSearchBar hideIco showStreetName rzpDetail callName />
          </Form.Item>
        )}

        <Form.Item
          label="Společnost"
          name="companyName"
          help={props.showHelp ? _help.companyName : undefined}
        >
          <Input placeholder="Název firmy, organizace, uskupení apod." />
        </Form.Item>

        <_PersonFragment showHelp={props.showHelp} />

        <Divider dashed />

        {!props.hideAddressSearch && (
          <_AddressSearch showHelp={props.showHelp} />
        )}

        <_StreetFragment showHelp={props.showHelp} />

        <Form.Item
          label="Obec"
          name="city"
          rules={itemRequired('Zadejte název obce')}
        >
          <Input placeholder="Obec" />
        </Form.Item>

        <Form.Item
          label="PSČ"
          name="zipCode"
          rules={itemRequired('PSČ je povinné')}
          help={props.showHelp ? _help.zipCode : undefined}
        >
          <Input placeholder="PSČ" />
        </Form.Item>

        <_CountrySelect showHelp={props.showHelp} />

        <_VariablesFragment showHelp={props.showHelp} />

        <Divider plain>
          <span className="text-gray-400">Údaje o nemovitosti adresáta</span>
        </Divider>
        <_PropertyFragment showHelp={props.showHelp} />

        <Divider />

        <Form.Item name="addressId" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="uniquePersonKey" hidden>
          <Input />
        </Form.Item>

        <Form.Item className="!mb-0" {...fullItemLayoutProps}>
          <div className="flex justify-between">
            <Button
              icon={<InfoCircleOutlined />}
              type="link"
              onClick={props.handleHelpToggle}
            >
              {props.showHelp ? 'Skrýt nápovědu' : 'Zobrazit nápovědu'}
            </Button>

            <div className="space-x-2">
              <Button disabled={loading} onClick={props.hideModal}>
                Zrušit
              </Button>

              {!props.initialValues?.id && !props.hideAddAnother && (
                <Button
                  loading={false}
                  type="primary"
                  ghost
                  onClick={submitAndAddAnother}
                >
                  Uložit a přidat další
                </Button>
              )}

              <Button type="primary" loading={loading} onClick={submit}>
                Uložit a zavřít
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    )
  }
